import React from "react";
import styled from "styled-components";
import Colors from "../assets/theme/colors";
import InstagramIcon from "./InstagramIcon";
import BlogIcon from "./BlogIcon";
// import YoutubeIcon from './YoutubeIcon'
import Map from "../assets/image/web/map.png";

const MapWrap = styled.div`
  max-width: 852px;
  img {
    width: 100%;
  }

  @media all and (min-width: 641px) {
    margin-top: 76px;
  }

  @media all and (max-width: 640px) {
  }
`;

const MapDetail = styled.table`
  border-top: 1px solid ${Colors.black_second};
  border-bottom: 1px solid ${Colors.black_second};
  width: 100%;
  border-collapse: collapse;

  tr {
    height: 88px;
    th {
      color: ${Colors.green};
      font-family: "GmarketSans", sans-serif;
      text-align: left;
      border-bottom: 1px solid #c9c9c9;
    }
    td {
      color: ${Colors.black};
      font-family: "GmarketSans", sans-serif;
      text-align: left;
      font-weight: 500;
      line-height: 1.7;
      border-bottom: 1px solid #c9c9c9;
    }
    td.sns {
      svg {
        margin-right: 30px;
        vertical-align: middle;
      }
    }
  }
  tr:last-child {
    height: 143px;
  }
  tr:last-child th,
  tr:last-child td {
    border-bottom: 0;
  }

  @media all and (min-width: 641px) {
    margin-top: 56px;
    margin-bottom: 197px;
    tr {
      th {
        width: 20%;
        font-size: 18px;
        padding-left: 20px;
      }
      td {
        width: 80%;
        font-size: 18px;
      }
    }
  }

  @media all and (max-width: 640px) {
    margin-top: 40px;
    margin-bottom: 80px;
    tr {
      height: auto;
      th {
        width: 30%;
        font-size: 12px;
        padding-left: 10px;
        padding-top: 25px;
        padding-bottom: 25px;
      }
      td {
        width: 70%;
        font-size: 12px;
        padding-top: 25px;
        padding-bottom: 25px;
      }
      td.sns {
        svg {
          width: 24px;
        }
      }
    }
    tr:last-child {
      height: auto;
    }
  }
`;

const Infomation = () => {
  return (
    <MapWrap>
      <img src={Map} alt="Map" />
      <MapDetail>
        <tr>
          <th>위치</th>
          <td>대전 유성구 엑스포로 97번길 103(도룡동)</td>
        </tr>
        <tr className="place">
          <th>주변시설</th>
          <td>
            편의시설 - 신세계백화점, 스타벅스, DCC, 성심당(DCC점), 스마트시티(2,
            5단지)
            <br />
            관광시설 - 국립중앙과학관, 엑스포 과학공원, 한빛탑, 우성이산,
            갑천호수공원
          </td>
        </tr>
        <tr>
          <th>대표번호</th>
          <td>042 - 863 - 2030</td>
        </tr>
        <tr>
          <th>SNS</th>
          <td className="sns">
            <InstagramIcon color={Colors.black} />
            <BlogIcon color={Colors.black} />
            {/* <YoutubeIcon color={Colors.black} /> */}
          </td>
        </tr>
        <tr>
          <th>대상</th>
          <td>
            개인 - 청년근로자
            <br />
            단체 - 중소기업만 해당
          </td>
        </tr>
        <tr>
          <th>비용</th>
          <td>
            보증금 - 1,000,000원
            <br />
            임대료 - 250,000원
            <br />
            관리비 - 20,000원(인터넷 및 보안 등 이용료)
            <br />
            공과금 - 별도(전기, 가스, 수도 등)
          </td>
        </tr>
      </MapDetail>
    </MapWrap>
  );
};

export default Infomation;
