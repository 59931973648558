import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import Colors from "../assets/theme/colors";

import WebProgram01 from "../assets/image/web/community/필라테스_웹.jpg";
import WebProgram02 from "../assets/image/web/community/뜨개질_웹.jpg";
import WebProgram03 from "../assets/image/web/community/보석비누 클래스_웹.jpg";
import WebProgram04 from "../assets/image/web/community/도시농부 클래스_웹.jpg";
import WebProgram05 from "../assets/image/web/community/샴푸바 클래스_웹.jpg";
import WebProgram06 from "../assets/image/web/community/맨몸PT_웹.jpg";
import WebProgram07 from "../assets/image/web/community/라탄클래스_웹.jpg";
import WebProgram08 from "../assets/image/web/community/perfume_웹.jpg";
import WebProgram09 from "../assets/image/web/community/leather_웹.jpg";
import WebProgram10 from "../assets/image/web/community/마크라메_웹.jpg";
import WebProgram11 from "../assets/image/web/community/아로마_web.jpg";
import WebProgram12 from "../assets/image/web/community/레진_web.jpg";
import WebProgram13 from "../assets/image/web/community/반려식물 화분 클래스 web.jpg";
import WebProgram14 from "../assets/image/web/community/perfume_one_day_web.jpg";
import WebProgram15 from "../assets/image/web/community/고블렛잔 만들기 클래스_웹.jpg";
import WebProgram16 from "../assets/image/web/community/dojang_web.jpeg"
import WebProgram17 from "../assets/image/web/community/원데이 클래스 사진 웹.jpeg"

import MobileProgram01 from "../assets/image/mobile/community/필라테스_모바일.jpg";
import MobileProgram02 from "../assets/image/mobile/community/뜨개질_모바일.jpg";
import MobileProgram03 from "../assets/image/mobile/community/보석비누 클래스_모바일.jpg";
import MobileProgram04 from "../assets/image/mobile/community/도시농부 클래스_모바일.jpg";
import MobileProgram05 from "../assets/image/mobile/community/샴푸바 클래스_모바일.jpg";
import MobileProgram06 from "../assets/image/mobile/community/맨몸PT_모바일.jpg";
import MobileProgram07 from "../assets/image/mobile/community/라탄클래스_모바일.jpg";
import MoblieProgram08 from "../assets/image/mobile/community/perfume_모바일.jpg";
import MoblieProgram09 from "../assets/image/mobile/community/leather_모바일.jpg";
import MoblieProgram10 from "../assets/image/mobile/community/마크라메_모바일.jpg";
import MoblieProgram11 from "../assets/image/mobile/community/아로마_mobile.jpg";
import MoblieProgram12 from "../assets/image/mobile/community/레진_mobile.jpg";
import MoblieProgram13 from "../assets/image/mobile/community/반려식물 화분 클래스 Mobile.jpg";
import MoblieProgram14 from "../assets/image/mobile/community/perfume_one_day_mobile.jpg";
import MoblieProgram15 from "../assets/image/mobile/community/고블렛잔 만들기 클래스_모바일.jpg";
import MoblieProgram16 from "../assets/image/mobile/community/dojang_mobile.jpeg";
import MoblieProgram17 from "../assets/image/mobile/community/원데이 클래스 사진 모바일.jpeg";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  @media all and (min-width: 1024px) {
    width: 100vw;
  }

  @media all and (max-width: 1023px) {
    width: calc(100% + 40px);
  }

  @media all and (min-width: 641px) {
    margin-top: 170px;
    margin-bottom: 126px;
  }

  @media all and (max-width: 640px) {
    margin-top: 150px;
    max-width: 340px;
  }
`;

const Background = styled.div`
  position: absolute;
  background-color: ${Colors.green};
  z-index: 300;

  @media all and (min-width: 1920px) {
    height: 426px;
    width: 90vw;
  }

  @media all and (max-width: 1919px) {
    height: 426px;
    width: 100vw;
  }

  @media all and (max-width: 1023px) and (min-width: 641px) {
    width: 100%;
  }

  @media all and (min-width: 641px) {
    right: 0;
  }

  @media all and (max-width: 640px) {
    height: 398px;
  }

  @media all and (max-width: 360px) {
    width: calc(100% + 39px);
  }
`;

// const ContentsWrap = styled.div`
//   display: ${(props) => (props.hidden ? "none" : "flex")};
//   flex-wrap: wrap;
//   position: relative;
//   top: -80px;

//   @media all and (min-width: 1400px) {
//     width: 1315px;
//   }

//   @media all and (max-width: 1399px) {
//     width: 90vw;
//     flex-basis: 30%;
//     flex-grow: 1;
//     padding-left: 3vw;
//     padding-right: 3vw;
//   }

//   @media all and (min-width: 1024px) {
//     justify-content: space-between;
//   }

//   @media all and (max-width: 1023px) {
//     justify-content: space-evenly;
//   }
// `;

const SliderWrap = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  position: relative;
  top: -80px;
  width: 100vw;

  .slick-slider {
    z-index: 500;
    max-width: ${(props) => (!props.isMobile ? "1200px" : "340px")};
    margin: 0 auto;
  }

.slick-prev {
    left: -45px;
}

  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 45px;
    line-height: 1;
    opacity: 0.75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

  }

  .slick-track {
    height: 400px;
    // overflow-y: hidden;
    & > div > div {
      width: 320px;
    }
  }

  .slick-slide {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    overflow-y: hidden;

    @media all and (max-width: 1399px) {
      padding-left: 35px;
    @media all and (min-width: 641px) {
       padding-left: 35px;
  }
 
  }

  .slick-dots {
    bottom: -20px;
    li {
      width: fit-content;
      height: fit-content;
      margin: 0 9px 0 0;
      &:last-child {
        margin: 0;
      }
    }
  }

  .slick-active {
    button:before {
      background: ${Colors.white};
    }
    z-index: 999;
  }

  .slick-dots li button {
    width: 7px;
    height: 7px;
    &:before {
      content: "";
      display: inline-block;
      width: 7px;
      height: 7px;
      color: ${Colors.green};
      border: 1px solid ${Colors.white};
      border-radius: 50%;
      opacity: 1;
    }
  }
`;

const Contents = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 500;

  &:focus {
    border: 0;
    outline: 0;
  }

  @media all and (max-width: 1399px) {
    width: 30vw;
  }

  @media all and (min-width: 641px) {
    max-width: 405px;
    min-width: 307px;
  }
`;

const Picture = styled.div`
  display: flex;
  width: 100%;

  img {
    pointer-events: none;
    width: 100%;
    height: 100%;
  }

  @media all and (max-width: 640px) {
    width: 320px;
    height: 212px;
  }
`;

const Title = styled.div`
  width: fit-content;
  border: 1px solid ${Colors.white};
  padding: 13px 25px;
  border-radius: 17px;
  font-size: 20px;
  text-align: center;
  color: ${Colors.white};
  font-family: "GmarketSans", sans-serif;
  word-break: keep-all;

  @media all and (min-width: 641px) {
    margin-top: 26px;
    margin-bottom: 20px;
  }

  @media all and (max-width: 1023px) {
    line-height: 1.5;
  }

  @media all and (max-width: 640px) {
    margin-top: 18px;
    margin-bottom: 20px;
    padding: 8px 15px;
  }
`;

const Description = styled.div`
  font-family: "NotoSans", sans-serif;
  font-size: 15px;
  text-align: left;
  font-weight: 300;
  word-break: keep-all;
  color: ${Colors.white};

  @media all and (min-width: 641px) {
  }

  @media all and (max-width: 640px) {
  }
`;

const program = [
  {
    title: "캔들 클래스",
    webimage: WebProgram17,
    mobileimage: MoblieProgram17,
    webtext:
      "향, 왁스 등 취향껏 색상을 만들어주면서 눈으로 힐링하는 시간을 가질수 있는 프로그램입니다.",
    mobiletext:
      "향, 왁스 등 취향껏 색상을 만들어주면서 눈으로 힐링하는 시간을 가질수 있는 프로그램입니다.",
  },
  {
    title: "도장 만들기 클래스",
    webimage: WebProgram16,
    mobileimage: MoblieProgram16,
    webtext:
      "소중한 내 이름을 새길 수있고 평생을 사용할 수 있는 도장이 되도록 만드는 프로그램입니다.",
    mobiletext:
      "소중한 내 이름을 새길 수있고 평생을 사용할 수 있는 도장이 되도록 만드는 프로그램입니다.",
  },
  {
    title: "고블렛잔 만들기 클래스",
    webimage: WebProgram15,
    mobileimage: MoblieProgram15,
    webtext:
      "고블렛잔에 내가 원하는 도안으로 재미있게 그리고!자르고!붙이고! 특별한 날을 기억할 수 있는 프로그램입니다.",
    mobiletext:
      "고블렛잔에 내가 원하는 도안으로 재미있게 그리고!자르고!붙이고! 특별한 날을 기억할 수 있는 프로그램입니다.",
  },
  {
    title: "향수 원데이 클래스",
    webimage: WebProgram14,
    mobileimage: MoblieProgram14,
    webtext:
      "여러 향료들을 시향한 후 자신만의 느낌을 기록해 직접 맞춰가 레시피를 작성해 나만의 향수를 만드는 프로그램입니다.",
    mobiletext:
      "여러 향료들을 시향한 후 자신만의 느낌을 기록해 직접 맞춰가 레시피를 작성해 나만의 향수를 만드는 프로그램입니다.",
  },
  {
    title: "반려식물 화분 클래스",
    webimage: WebProgram13,
    mobileimage: MoblieProgram13,
    webtext:
      "다양한 질감 및 컬러를 사용하여 온전히 나만의 감성, 분위기를 담아 개성있는 화분을 만드는클래스입니다.",
    mobiletext:
      "다양한 질감 및 컬러를 사용하여 온전히 나만의 감성, 분위기를 담아 개성있는 화분을 만드는클래스입니다.",
  },
  {
    title: "아로마 클래스",
    webimage: WebProgram11,
    mobileimage: MoblieProgram11,
    webtext:
      "심리적 스트레스 해소와 안정감을 주는 아로마 오일을 활용하여 나에게 맞는 향기를 찾아 섬유유연제로 만드는 프로그램입니다.",
    mobiletext:
      "심리적 스트레스 해소와 안정감을 주는 아로마 오일을 활용하여 나에게 맞는 향기를 찾아 섬유유연제로 만드는 프로그램입니다.",
  },
  {
    title: "레진아트 클래스",
    webimage: WebProgram12,
    mobileimage: MoblieProgram12,
    webtext:
      "레진아트로 자신만의 그립톡을 디자인하여 제작하는 과정으로 성취감과 만족도가 높은 프로그램입니다.",
    mobiletext:
      "레진아트로 자신만의 그립톡을 디자인하여 제작하는 과정으로 성취감과 만족도가 높은 프로그램입니다.",
  },
  {
    title: "마크라메 클래스",
    webimage: WebProgram10,
    mobileimage: MoblieProgram10,
    webtext:
      "내가 들고 다니는 가방을 실 하나로 제작 가능하며 완성품에 대한 만족도가 높아 취미로도 이어지는 프로그램입니다.",
    mobiletext:
      "내가 들고 다니는 가방을 실 하나로 제작 가능하며 완성품에 대한 만족도가 높아 취미로도 이어지는 프로그램입니다.",
  },
  {
    title: "가죽공방 클래스",
    webimage: WebProgram09,
    mobileimage: MoblieProgram09,
    webtext:
      "세상 어디에도 없는 카드지갑을 누구나 쉽게 제작할 수 있게 진행하여 특별함과 완성품에 대한 성취감을 높이는 프로그램입니다.",
    mobiletext:
      "세상 어디에도 없는 카드지갑을 누구나 쉽게 제작할 수 있게 진행하여 특별함과 완성품에 대한 성취감을 높이는 프로그램입니다.",
  },
  {
    title: "보석비누 클래스",
    webimage: WebProgram03,
    mobileimage: MobileProgram03,
    webtext:
      "세정 효과뿐만 아닌 향과 함께 심미적인 효과를 줄 수\n있는 영롱한 보석 모양의 비누를 만들 수 있는 프로그램입니다",
    mobiletext:
      "세정 효과뿐만 아닌 향과 함께 심미적인 효과를 줄 수\n있는 영롱한 보석 모양의 비누를 만들 수 있는 프로그램입니다",
  },
  {
    title: "향수바 클래스",
    webimage: WebProgram08,
    mobileimage: MoblieProgram08,
    webtext:
      "내가 원하는 향을 선택하여 아로마 원료와 함께 제조하여 심신의 안정까지 느낄 수 있는 향수바 DIY 프로그램입니다.",
    mobiletext:
      "내가 원하는 향을 선택하여 아로마 원료와 함께 제조하여 심신의 안정까지 느낄 수 있는 향수바 DIY 프로그램입니다.",
  },
  {
    title: "맨몸PT 클래스",
    webimage: WebProgram06,
    mobileimage: MobileProgram06,
    webtext:
      "혼자서도 쉽게 따라할 수 있는 자세들로만 모아 칼로리와 체지방을 태워 건강한 몸을 만들 수 있는 기구없이 하는 피티 프로그램입니다.",
    mobiletext:
      "혼자서도 쉽게 따라할 수 있는 자세들로만 모아 칼로리와 체지방을 태워 건강한 몸을 만들 수 있는 기구없이 하는 피티 프로그램입니다.",
  },
  {
    title: "라탄 클래스",
    webimage: WebProgram07,
    mobileimage: MobileProgram07,
    webtext:
      "내손으로 직접 만드는 활용적이고 시원한 인테리어 느낌을 줄 수 있는 친환경적인 작품을 만드는 프로그램입니다.",
    mobiletext:
      "내손으로 직접 만드는 활용적이고 시원한 인테리어 느낌을 줄 수 있는 친환경적인 작품을 만드는 프로그램입니다.",
  },
  {
    title: "필라테스&요가",
    webimage: WebProgram01,
    mobileimage: MobileProgram01,
    webtext:
      "일상생활 속 틀어진 자세 교정으로 혈액 순환이 되며 유연성과 근력 증강에 효과를 느낄 수 있는 프로그램입니다.",
    mobiletext:
      "일상생활 속 틀어진 자세 교정으로 혈액 순환이 되며 유연성과 근력 증강에 효과를 느낄 수 있는 프로그램입니다.",
  },
  {
    title: "뜨개질 클래스",
    webimage: WebProgram02,
    mobileimage: MobileProgram02,
    webtext:
      "손재주가 없어도 누구나 참여 가능하며 완성품에 대한 성취감으로 주의력 과 집중력 향상에 도움을 주는 프로그램입니다.",
    mobiletext:
      "손재주가 없어도 누구나 참여 가능하며 완성품에 대한 성취감으로 주의력 과 집중력 향상에 도움을 주는 프로그램입니다.",
  },
  {
    title: "도시농부 클래스",
    webimage: WebProgram04,
    mobileimage: MobileProgram04,
    webtext:
      "도심속에서 힐링할 수 있는 식물 기르기 체험으로\n내 손으로 직접 토마토 모종을 심고 길러보며 수확의 기쁨을 느낄 수 있는 프로그램 입니다.",
    mobiletext:
      "도심속에서 힐링할 수 있는 식물 기르기 체험으로\n내 손으로 직접 토마토 모종을 심고 길러보며 수확의 기쁨을 느낄 수 있는 프로그램 입니다.",
  },
  {
    title: "샴푸바 클래스",
    webimage: WebProgram05,
    mobileimage: MobileProgram05,
    webtext:
      "친환경 재료로 몸에 좋은 성분이 가득 담겨 있는 나만의 고체형 샴푸를 만들어 보는 프로그램 입니다.",
    mobiletext:
      "친환경 재료로 몸에 좋은 성분이 가득 담겨 있는 나만의 고체형 샴푸를 만들어 보는 프로그램 입니다.",
  },
];

const Program = () => {
  const isMobile = useMediaQuery("(max-width: 640px)");

  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: isMobile ? 1 : 3,
  };

  return (
    <Container>
      <Background />
      <SliderWrap isMobile={isMobile}>
        <Slider {...settings}>
          {program.map((program, i) => (
            <Contents key={i}>
              <Picture>
                <img src={program.mobileimage} alt={program.title} />
              </Picture>
              <Title>{program.title}</Title>
              <Description>
                {program.mobiletext.split("\n").map((text, index) => (
                  <span key={index}>
                    {text}
                    <br />
                  </span>
                ))}
              </Description>
            </Contents>
          ))}
        </Slider>
      </SliderWrap>
      {/* <ContentsWrap hidden={isMobile === true}>
        {program.map((program, i) => (
          <Contents key={i}>
            <Picture>
              <img src={program.webimage} alt={program.title} />
            </Picture>
            <Title>{program.title}</Title>
            <Description>
              {program.webtext.split("\n").map((text, index) => (
                <span key={index}>
                  {text}
                  <br />
                </span>
              ))}
            </Description>
          </Contents>
        ))}
      </ContentsWrap> */}
    </Container>
  );
};

export default Program;
