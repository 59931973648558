import React from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import whiteIcon from '../assets/image/groundnote-white.png'
import greyIcon from '../assets/image/groundnote.png'

const GroundnoteIcon = props => {
  const markeyUrl = (() => {
    if (isAndroid) return 'https://play.google.com/store/apps/details?id=com.groundnote.app&hl=en_US&gl=US'
    else if (isIOS) return 'https://apps.apple.com/kr/app/groundnote/id1515237550'
    else return 'https://play.google.com/store/apps/details?id=com.groundnote.app&hl=en_US&gl=US'
  })()

  return (
    <img src={props.white ? whiteIcon : greyIcon} alt="Groundnote" onClick={() => { window.location.href=markeyUrl}} />
  )
}

export default GroundnoteIcon
