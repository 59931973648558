import React, { useState, useEffect } from "react";
import AOS from "aos";
import styled from "styled-components";
import Styles from "../assets/theme/styles";
import ScrollToTop from "./ScrollToTop";
import Popup from "./Popup";
import HwpFile from "../assets/file/대전청년하우스 입주자 모집 공고문.hwp";
import PdfFile from "../assets/file/대전청년하우스 입주자 모집 공고문.pdf";
import { Route } from "react-router-dom";
import { Home, About, House, Community } from "../pages";

// diplay 주석 처리 했는데.. 이게 과연 뭘까?
const ButtonWrap = styled.div`
  // display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  right: 1vw;
  bottom: 9vw;
  // z-index: ${(props) => props.level};
  z-index: 999;
`;

const DownloadBtn = styled(Styles.ContactBtn)`
  &::before {
    content: "입주";
  }
  &::after {
    content: "공고문";
  }

  @media all and (min-width: 641px) {
    margin-bottom: 20px;
  }

  @media all and (max-width: 640px) {
    margin-bottom: 12px;
  }
`;

const ContactBtn = styled(Styles.ContactBtn)`
  &::before {
    content: "입주";
  }
  &::after {
    content: "문의하기";
  }
`;

const Contents = ({ openContract, onOpenContract }) => {
  const [scrollTop, setScrollTop] = useState(0);
  const [isPopup, setPopup] = useState(false);
  const HAS_VISITED_BEFORE = localStorage.getItem("hasVisitedBefore");
  useEffect(() => {
    AOS.init({
      duration: 600,
    });

    window.addEventListener("scroll", (e) => {
      setScrollTop(e.srcElement.scrollingElement.scrollTop);
    });

    return (_) => {
      setScrollTop(0);
    };
  }, []);

  useEffect(() => {
    const showPopup = () => {
      if (HAS_VISITED_BEFORE > new Date()) {
        return;
      }
      if (!HAS_VISITED_BEFORE || HAS_VISITED_BEFORE < new Date()) {
        setPopup(true);
      }
    };
    window.setTimeout(showPopup, 1000);
  }, [HAS_VISITED_BEFORE]);

  const handleDownload = () => {
    const fileNm = "대전청년하우스 입주자 모집 공고문"; // NOTE: Export 파일명

    const hwpFile = document.createElement("a");
    hwpFile.href = HwpFile;
    hwpFile.download = `${fileNm}.hwp`;
    hwpFile.click();

    const pdfFile = document.createElement("a");
    pdfFile.href = PdfFile;
    pdfFile.download = `${fileNm}.pdf`;
    pdfFile.click();
  };

  return (
    <div>
      <ScrollToTop />
      <ButtonWrap
        data-aos="fade-up"
        visible={scrollTop > 220}
        level={openContract ? "auto" : 600}
      >
        <DownloadBtn onClick={() => handleDownload()} />
        <ContactBtn onClick={() => onOpenContract(true)} />
      </ButtonWrap>
      {/* 아래 home 컴포넌트에 '/'path로 랜더링 될 때 isPopup={isPopup} 추가 해주면 팝업창 나옴  */}
      <Route exact path="/" render={() => <Home contract={onOpenContract} />} />
      <Route path="/about" render={() => <About contract={onOpenContract} />} />
      <Route path="/house" render={() => <House contract={onOpenContract} />} />
      <Route
        path="/community"
        render={() => <Community contract={onOpenContract} />}
      />
      <Route path="/faq" render={() => <Home contract={onOpenContract} />} />
      <Route
        path="/popup"
        render={() => <Popup handleDownload={handleDownload} />}
      />
    </div>
  );
};

export default Contents;
