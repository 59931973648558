const Colors = ({
  white: '#ffffff',
  white_light: 'rgba(255, 255, 255, .5)',
  green: '#16634f',
  grey: '#898989',
  black: '#393838',
  black_second: '#404040',
  bg_bluegrey: '#f4f8f7',
  bg_grey: '#f9f9f9',
  grey_second: '#d8d8d8'
})

export default Colors
