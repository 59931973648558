import React, { useState } from "react";
import styled from "styled-components";
import Styles from "../assets/theme/styles";
import Colors from "../assets/theme/colors";
import TypeBtn from "../components/TypeBtn";
import BedIcon from "../assets/image/web/house/options/icon-bed.png";
import DeskIcon from "../assets/image/web/house/options/icon-desk.png";
import ChairIcon from "../assets/image/web/house/options/icon-chair.png";
import ClosetIcon from "../assets/image/web/house/options/icon-closet.png";
import ShoerackIcon from "../assets/image/web/house/options/icon-shoerack.png";
import RefrigeratorIcon from "../assets/image/web/house/options/icon-refrigerator.png";
import TvIcon from "../assets/image/web/house/options/icon-tv.png";
import WifiIcon from "../assets/image/web/house/options/icon-wifi.png";
import AddIcon from "../assets/image/button-add.png";
import ElectLogo from "../assets/image/logo/elect.png";
import NefsLogo from "../assets/image/logo/nefs.png";
import Facility from "../components/Facility";
import Header from "../components/Header";
import Room01 from "../assets/image/web/house/room/full_window.png";
import Room02 from "../assets/image/web/house/room/half_window.png";
import Room03 from "../assets/image/web/house/room/balcony.png";
import Room04 from "../assets/image/web/house/room/cozy_window.png";
import LeftTag from "../assets/image/svg/tag-left.svg";
import RightTag from "../assets/image/svg/tag-right.svg";

const Contents = styled(Styles.Contents)``;

const Container = styled(Styles.Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${Colors.bg_grey};

  @media all and (min-width: 641px) {
    padding-top: 200px;
    &:first-child {
      padding-top: 150px;
    }
  }

  @media all and (max-width: 640px) {
    padding-top: 120px;
    &:first-child {
      padding-top: 95px;
    }
  }
`;
const GreenContainer = styled(Styles.GreenContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > * > * {
    color: ${Colors.white};
  }
  @media all and (min-width: 641px) {
    padding-top: 200px;
    &:first-child {
      padding-top: 150px;
    }
  }

  @media all and (max-width: 640px) {
    padding-top: 120px;
    &:first-child {
      padding-top: 95px;
    }
  }
`;

const Paper = styled(Styles.Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Styles.Title)`
  margin-bottom: 44px;
`;

const Description = styled(Styles.Description)``;

const RoomTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3px;
  }

  @media all and (min-width: 641px) {
    margin-bottom: 25px;
    margin-top: 117px;
    & div > div {
      margin-right: 6px;
    }
  }
  @media all and (max-width: 640px) {
    margin-bottom: 20px;
    margin-top: 50px;
    flex-wrap: wrap;
    & div > div {
      margin-right: 3px;
    }
  }
`;

const RoomWrap = styled.div`
  position: relative;
  & > img {
    width: 100%;
  }
`;

const RoomCover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  &.type01 {
    .refrigerator {
      left: 5%;
      bottom: 10%;
    }
    .closet {
      top: 50%;
      left: 20%;
    }
    .desk {
      top: 75%;
      right: 30%;
    }
    .tv {
      right: 20%;
      top: 60%;
    }
  }

  &.type02 {
    .refrigerator {
      right: 5%;
      bottom: 10%;
    }
    .closet {
      top: 50%;
      right: 20%;
    }
    .desk {
      top: 80%;
      left: 30%;
    }
    .tv {
      left: 10%;
      top: 65%;
    }
  }

  &.type03 {
    .refrigerator {
      left: 5%;
      bottom: 10%;
    }
    .closet {
      top: 50%;
      left: 20%;
    }
    .desk {
      top: 83%;
      right: 30%;
    }
    .tv {
      right: 10%;
      top: 60%;
    }
  }

  &.type04 {
    .refrigerator {
      left: 5%;
      bottom: 10%;
    }
    .closet {
      top: 50%;
      left: 30%;
    }
    .desk {
      top: 60%;
      right: 10%;
    }
    .tv {
      right: 2%;
      top: 50%;
    }
  }

  @media all and (max-width: 640px) {
    &.type03 {
      .desk {
        top: 78%;
        right: 30%;
      }
    }
  }
`;

const Point = styled.div`
  position: absolute;

  & > img {
    width: 27px;
    height: 27px;
    cursor: pointer;
    z-index: 500;
  }

  &:hover > div {
    display: flex;
  }

  @media all and (max-width: 640px) {
    & > img {
      width: 17px;
      height: 17px;
    }
  }
`;

const Tooltip = styled.div`
  display: none;
  width: 139px;
  height: 40px;
  background-image: url(${(props) => (props.left ? LeftTag : RightTag)});
  background-repeat: no-repeat;
  font-size: ${(props) => (props.direction === "row" ? "12px" : "10px")};
  font-family: "GmarketSans", sans-serif;
  text-align: center;
  color: #393838;
  font-weight: 500;
  line-height: 1.5;
  position: absolute;
  align-items: center;
  justify-content: center;
  z-index: 600;
  div {
    position: relative;
    right: ${(props) => (props.left ? "-5px" : "5px")};
    display: flex;
    flex-direction: ${(props) => props.direction};
    align-items: center;
    justify-content: center;
  }
  div p {
    margin: 0;
    font-family: "GmarketSans", sans-serif;
    padding-left: ${(props) => (props.direction === "row" ? "5px" : "0px")};
  }

  @media all and (min-width: 641px) {
    left: ${(props) => (props.left ? "35px" : "-146px")};
    top: -6px;
  }

  @media all and (max-width: 640px) {
    left: ${(props) => (props.left ? "20px" : "-142px")};
    top: -10px;
  }
`;

const FurnitureWrap = styled.div`
  width: 100%;
  max-width: 900px;

  & > div {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  @media all and (min-width: 641px) {
    display: ${(props) => (props.web ? "flex" : "none")};
    flex-direction: row;
    margin-top: 100px;
    margin-bottom: 200px;
    & > div {
      justify-content: space-between;
    }
  }
  @media all and (max-width: 640px) {
    display: ${(props) => (props.mobile ? "flex" : "none")};
    flex-direction: column;
    margin-top: 57px;
    margin-bottom: 120px;
    & > div {
      justify-content: center;
      &:first-child {
        & > div {
          &:first-child {
            width: 50px;
            img {
              width: 72%;
            }
          }
          &:nth-child(2) {
            width: 52px;
            img {
              width: 65%;
            }
          }
        }
      }
    }
    & > div:last-child {
      margin-top: 30px;
    }
  }
`;

const Furniture = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;

  @media all and (min-width: 641px) {
    img {
      padding-bottom: 19px;
    }
    &:after {
      content: "${(props) => props.label}";
      display: block;
      color: ${Colors.black};
      font-family: "NotoSans", sans-serif;
      font-size: 18px;
      text-align: center;
    }
  }
  @media all and (max-width: 640px) {
    img {
      padding-bottom: 12px;
      width: 50%;
    }
    &:after {
      content: "${(props) => props.label}";
      display: block;
      color: ${Colors.black};
      font-family: "NotoSans", sans-serif;
      font-size: 10px;
      text-align: center;
    }
  }
`;

const FloorWrap = styled.div`
  width: 100%;
  max-width: 840px;
  color: ${Colors.white};
  font-family: "GmarketSans", sans-serif;
  flex-direction: column;

  @media all and (min-width: 641px) {
    font-size: 18px;
    margin-top: 152px;
    margin-bottom: 132px;
  }

  @media all and (max-width: 640px) {
    font-size: 12px;
    font-weight: 300;
    margin-top: 60px;
    margin-bottom: 60px;
  }
`;

const Floor = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 0.5px solid ${Colors.white_light};
  &:last-child {
    border-bottom: 0;
  }
  @media all and (min-width: 641px) {
    padding: 48px 0;
  }
  @media all and (max-width: 640px) {
    padding: 30px 0;
  }
`;

const SubFloor = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
  }
  & > div:last-child {
    margin-bottom: 0px;
  }
`;

const FloorText = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  word-break: break-word;

  @media all and (min-width: 641px) {
    min-width: 150px;
  }

  @media all and (max-width: 640px) {
    min-width: 66px;
  }
`;

const FloorTitle = styled.div`
  font-weight: bold;

  @media all and (min-width: 641px) {
    min-width: 230px;
    & > div {
      padding-bottom: 65px;
    }
    & > div:last-child {
      padding-bottom: 0;
    }
  }

  @media all and (max-width: 640px) and (min-width: 415px) {
    min-width: 100px;
  }

  @media all and (max-width: 414px) {
    min-width: 67px;
    & > div {
      padding-bottom: 51px;
    }
    & > div:last-child {
      padding-bottom: 0;
    }
  }
`;

const FloorContent = styled.div`
  font-family: "NotoSans", sans-serif;

  @media all and (min-width: 641px) {
    & > div {
      padding-bottom: 65px;
    }
    & > div:last-child {
      padding-bottom: 0;
    }
  }

  @media all and (max-width: 640px) {
    min-width: 157px;
    & > div {
      padding-bottom: 30px;
    }
    & > div:last-child {
      padding-bottom: 0;
    }
  }
`;

const SliderWrap = styled.div`
  width: 100vw;

  @media all and (min-width: 641px) {
    margin-bottom: 240px;
  }

  @media all and (max-width: 640px) {
    margin-bottom: 79px;
  }
`;

const FurnitureOptions = [
  {
    icon: <img src={BedIcon} alt="침대" />,
    label: "침대",
  },
  {
    icon: <img src={DeskIcon} alt="책상" />,
    label: "책상",
  },
  {
    icon: <img src={ChairIcon} alt="의자" />,
    label: "의자",
  },
  {
    icon: <img src={ClosetIcon} alt="옷장" />,
    label: "옷장",
  },
  {
    icon: <img src={ShoerackIcon} alt="신발장" />,
    label: "신발장",
  },
  {
    icon: <img src={RefrigeratorIcon} alt="냉장고" />,
    label: "냉장고",
  },
  {
    icon: <img src={WifiIcon} alt="WIFI" />,
    label: "WIFI",
  },
  {
    icon: <img src={TvIcon} alt="TV" />,
    label: "TV",
  },
];

const RoomImage = [Room01, Room02, Room03, Room04];

const RoomTitle = [
  "Full Window Type",
  "Half Window Type",
  "Balcony Type",
  "Cozy Window Type",
];

const House = ({ contract }) => {
  const [activeRoom, setActiveRoom] = useState(0);

  return (
    <Contents>
      <Header
        color={Colors.black}
        mobileColor={Colors.green}
        border={Colors.grey_second}
        contract={contract}
      />
      <Container>
        <Paper>
          <Title>객실 소개</Title>
          <Description>
            대전청년하우스에는 4가지 타입의 창문이 있습니다.
            <br />
            가구 레이아웃을 창문 타입에 맞게 재구성 하실 수 있습니다.
          </Description>
          <RoomTab>
            <div>
              <TypeBtn
                active={activeRoom === 0}
                onClick={() => setActiveRoom(0)}
              >
                {RoomTitle[0]}
              </TypeBtn>
              <TypeBtn
                active={activeRoom === 1}
                onClick={() => setActiveRoom(1)}
              >
                {RoomTitle[1]}
              </TypeBtn>
            </div>
            <div>
              <TypeBtn
                active={activeRoom === 2}
                onClick={() => setActiveRoom(2)}
              >
                {RoomTitle[2]}
              </TypeBtn>
              <TypeBtn
                active={activeRoom === 3}
                onClick={() => setActiveRoom(3)}
              >
                {RoomTitle[3]}
              </TypeBtn>
            </div>
          </RoomTab>
          <RoomWrap>
            <RoomCover className={`type0${activeRoom + 1}`}>
              <Point className="refrigerator">
                <img src={AddIcon} alt="냉장고" />
                <Tooltip
                  direction="row"
                  left={activeRoom !== 1}
                  right={activeRoom === 1}
                >
                  <div>
                    <img
                      src={ElectLogo}
                      alt="냉장고"
                      width="26px"
                      height="24px"
                    />
                    <p>냉장고</p>
                  </div>
                </Tooltip>
              </Point>
              <Point className="closet">
                <img src={AddIcon} alt="옷장" />
                <Tooltip
                  direction="column"
                  left={activeRoom !== 1}
                  right={activeRoom === 1}
                >
                  <div>
                    <img src={NefsLogo} alt="옷장" width="43px" height="9px" />
                    <p>옷장</p>
                  </div>
                </Tooltip>
              </Point>
              <Point className="desk">
                <img src={AddIcon} alt="책상" />
                <Tooltip
                  direction="column"
                  right={activeRoom !== 1}
                  left={activeRoom === 1}
                >
                  <div>
                    <img src={NefsLogo} alt="책상" width="43px" height="9px" />
                    <p>책상</p>
                  </div>
                </Tooltip>
              </Point>
              <Point className="tv">
                <img src={AddIcon} alt="FHD TV" />
                <Tooltip
                  direction="column"
                  right={activeRoom !== 1}
                  left={activeRoom === 1}
                >
                  <div>
                    <p>FHD TV</p>
                  </div>
                </Tooltip>
              </Point>
            </RoomCover>
            <img src={RoomImage[activeRoom]} alt={RoomTitle[activeRoom]} />
          </RoomWrap>
        </Paper>
      </Container>
      <Container>
        <Paper>
          <Title>객실 가구 옵션</Title>
          <Description>
            Nefs 가구, TV, 일렉트로맨 냉장고 등<br />
            최고급 사양의 옵션이 모두 제공됩니다.
          </Description>
          <FurnitureWrap web>
            <div>
              {FurnitureOptions.map((option, index) => (
                <Furniture key={`furniture-${index}`} label={option.label}>
                  {option.icon}
                </Furniture>
              ))}
            </div>
          </FurnitureWrap>
          <FurnitureWrap mobile>
            <div>
              {FurnitureOptions.slice(0, 4).map((option, index) => (
                <Furniture key={`furniture-${index}`} label={option.label}>
                  {option.icon}
                </Furniture>
              ))}
            </div>
            <div>
              {FurnitureOptions.slice(3).map((option, index) => (
                <Furniture key={`furniture-${index}`} label={option.label}>
                  {option.icon}
                </Furniture>
              ))}
            </div>
          </FurnitureWrap>
        </Paper>
      </Container>
      <GreenContainer>
        <Paper>
          <Title>층별 시설 안내</Title>
          <Description>
            층별로 다양한 편의시설이 있는 대전청년하우스의 공간을 소개합니다.
            <br />
            넓은 공용공간에서 한층 더 활기찬 생활을 즐겨보세요
          </Description>
          <FloorWrap>
            <Floor>
              <FloorText>1F</FloorText>
              <FloorTitle>Lounge</FloorTitle>
              <FloorContent>
                큐레이션 된 책들과 많은 인원이 모일 수 있는 공간으로 회의,
                원데이 클래스 진행이 가능 한 영감을 얻을 수 있는 공간
              </FloorContent>
            </Floor>
            <Floor>
              <FloorText>2F</FloorText>
              <SubFloor>
                <div className="subfloor">
                  <FloorTitle>Kitchen</FloorTitle>
                  <FloorContent>
                    인덕션, 에어프라이기, 밥솥, 오븐, 전자레인지 등이 갖추어져
                    다양한 요리를 즐길 수 있는 공유주방
                  </FloorContent>
                </div>
                <div className="subfloor">
                  <FloorTitle>Laundry</FloorTitle>
                  <FloorContent>
                    세제와 섬유유연제가 모두 갖춰진 세탁장비와 건조기가 있는
                    편리한 세탁실
                  </FloorContent>
                </div>
                <div className="subfloor">
                  <FloorTitle>Fitness Room</FloorTitle>
                  <FloorContent>
                    다양한 최신 운동기구들로 세팅된 휘트니스 공간
                  </FloorContent>
                </div>
              </SubFloor>
            </Floor>
            <Floor>
              <FloorText>3F</FloorText>
              <FloorTitle>Meeting Room</FloorTitle>
              <FloorContent>
                고사양의 영상, 음향 장비 등이 갖춰진 회의 및 커뮤니티 공간
              </FloorContent>
            </Floor>
            <Floor>
              <FloorText>4, 10F</FloorText>
              <FloorTitle>GX Room</FloorTitle>
              <FloorContent>
                폼롤러와 요가매트가 구비되어있는 공간으로 요가나 필라테스 등을
                할 수 있는 공간
              </FloorContent>
            </Floor>
            <Floor>
              <FloorText>5, 7, 9F</FloorText>
              <FloorTitle>Relax Room</FloorTitle>
              <FloorContent>
                1인 리클라이너 등 릴렉스 서비스를 통한 휴식과 힐링의 공간
              </FloorContent>
            </Floor>
            <Floor>
              <FloorText>6, 8F</FloorText>
              <FloorTitle>Study Lounge</FloorTitle>
              <FloorContent>
                주변의 방해 없이 업무나 공부에 집중할 수 있는 조용한 공간
              </FloorContent>
            </Floor>
          </FloorWrap>
          <SliderWrap>
            <Facility />
          </SliderWrap>
        </Paper>
      </GreenContainer>
    </Contents>
  );
};

export default House;
