import React, { useState } from "react";
import emailjs from "emailjs-com";
import clsx from "clsx";
import styled from "styled-components";
import Colors from "../assets/theme/colors";
import Styles from "../assets/theme/styles";
import CloseIcon from "../components/CloseIcon";
import LinkIcon from "../assets/image/arrow-up-right.png";
import DialogTheme from "../assets/theme/dialog";
import DatePicker, { registerLocale } from "react-datepicker";
import { MuiThemeProvider, Dialog } from "@material-ui/core";
import { isAndroid, isIOS } from "react-device-detect";

import "react-datepicker/dist/react-datepicker.css";
import "../assets/theme/datepicker.css";
import ko from "date-fns/locale/ko";

registerLocale("ko", ko);

const Header = styled.div`
  display: flex;
  top: 0;
  padding: 12px;
  justify-content: flex-end;
`;

const Contents = styled.div`
  form {
    & > div:first-child {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      padding-left: 40px;
      padding-right: 40px;
    }
  }
`;

const Title = styled.div`
  font-size: 30px;
  color: ${Colors.black};
  font-weight: 500;
  font-family: "GmarketSans", sans-serif;
  text-align: left;
  margin-bottom: 24px;
  margin-top: 24px;
  word-break: keep-all;

  @media all and (max-width: 640px) {
    font-size: 24px;
  }
`;

const Description = styled.div`
  color: ${Colors.black};
  font-weight: 300;
  font-family: "GmarketSans", sans-serif;
  text-align: left;
  margin-bottom: 26.5px;
  word-break: keep-all;
  line-height: 1.5;
  span {
    font-family: "GmarketSans", sans-serif;
  }

  @media all and (min-width: 641px) {
    font-size: 18px;
    span {
      font-size: 14px;
    }
  }

  @media all and (max-width: 640px) {
    font-size: 12px;
    span {
      font-size: 8px;
    }
  }
`;

const Link = styled.div`
  display: inline-block;
  border-bottom: 1px solid ${Colors.green};

  a {
    color: ${Colors.green};
    font-size: 18px;
    height: 24px;
    font-family: "NotoSans", sans-serif;
    cursor: pointer;
    text-decoration: none;
  }

  &:after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 21px;
    background-image: url(${LinkIcon});
    background-size: 20px;
    background-repeat: no-repeat;
    vertical-align: sub;
  }

  @media all and (max-width: 640px) {
    a {
      font-size: 14px;
    }
    &:after {
      width: 16px;
      height: 17px;
      background-size: 17px;
      vertical-align: middle;
    }
  }
`;

const Footer = styled.div`
  width: 100%;
  margin-top: 60px;

  & > div {
    height: 100%;
  }

  @media all and (min-height: 820px) {
    position: absolute;
    bottom: 0;
  }

  @media all and (min-width: 641px) {
    height: 77px;
  }

  @media all and (max-width: 640px) {
    height: 50px;
  }
`;

const Button = styled.button`
  cursor: pointer;
  width: 100%;
  height: 100%;
  color: white;
  background-color: ${Colors.green};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "GmarketSans", sans-serif;
  border: 0;
  &:focus {
    outline: none;
  }

  @media all and (min-width: 641px) {
    font-size: 20px;
  }

  @media all and (max-width: 640px) {
    font-size: 18px;
  }
`;

const CloseButton = styled.button`
  border: 0;
  display: flex;
  cursor: pointer;
  background: none;
  &:focus {
    outline: none;
  }
`;

const Input = styled(Styles.Input)``;

const Select = styled(Styles.Select)``;

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5vh;

  & > div {
    margin-bottom: 20px;
  }
  & > div:last-child {
    margin-bottom: 0;
  }

  .calendar {
    width: 25px;
    height: 25px;
  }

  @media all and (min-width: 641px) {
    width: 476px;
  }

  @media all and (max-width: 640px) {
    width: 280px;
  }
`;

const AgreeWrap = styled.div`
  color: #8d8d8d;
  font-size: 14px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  height: 18px;

  label {
    cursor: pointer;
    display: inline-block;
    & > span {
      color: #002a8a;
      border-bottom: 1px solid #002a8a;
    }
  }

  input {
    cursor: pointer;
    width: 18px;
    height: 18px;
    border-radius: 0;
    margin-right: 12px;
  }

  &.success {
    color: #16634f;
  }

  &.error {
    color: #dc0e0e;
  }

  @media all and (max-width: 640px) {
    font-size: 12px;
    input {
      margin-right: 10px;
    }
  }
`;

const Contract = ({ open, onClose }) => {
  const [name, setName] = useState(false);
  const [gender, setGender] = useState(false);
  const [phone, setPhone] = useState(false);
  const [email, setEmail] = useState(false);
  const [age, setAge] = useState(false);
  const [date, setDate] = useState(null);
  const [checkbox, setCheckbox] = useState(false);
  const [check, setCheck] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [status] = useState("error");

  const onSubmit = () => {
    setIsClick(true);
    if (
      name !== "" &&
      gender !== "" &&
      phone !== "" &&
      email !== "" &&
      age !== "" &&
      // date !== null &&
      checkbox !== false
    ) {
      setCheck(true);
    }
  };

  const sendEmail = (e) => {
    if (check === true) {
      e.preventDefault();
      emailjs
        .sendForm(
          "service_yw6clm4",
          "template_d511m0y",
          e.target,
          "user_I8AVW7Nty6IIXNsNWajd9"
        )
        .then(
          (result) => {
            console.log(result.text);
            alert("전송되었습니다.");
            window.location.reload();
          },
          (error) => {
            console.log(error.text);
            alert("전송에 실패했습니다.");
          }
        );
    }
  };

  const markeyUrl = (() => {
    if (isAndroid)
      return "https://play.google.com/store/apps/details?id=com.groundnote.app&hl=en_US&gl=US";
    else if (isIOS)
      return "https://apps.apple.com/kr/app/groundnote/id1515237550";
    else
      return "https://play.google.com/store/apps/details?id=com.groundnote.app&hl=en_US&gl=US";
  })();

  return (
    <MuiThemeProvider theme={DialogTheme}>
      <Dialog id="contract" open={open} maxWidth="md">
        <Header>
          <CloseButton onClick={() => onClose(false)}>
            <CloseIcon color={Colors.black} />
          </CloseButton>
        </Header>
        <Contents>
          <form onSubmit={sendEmail}>
            <div>
              <Title>{`룸 투어 & 상담 예약`}</Title>
              <Description>
                룸투어 가능시간
                <br />
                평일 : 10 ~ 20시 / 주말: 10 ~ 18시
                <br />
                <span>
                  *룸 컨디션 및 상황에 따라 시설 방문이 제한 되실 수 있습니다.
                </span>
              </Description>
              {/* <Link>
                <a href={markeyUrl} target="_blank" rel="noreferrer">
                  그라운드노트 APP에서 예약하기
                </a>
              </Link> */}
              <InputWrap>
                <div>
                  <Input
                    placeholder="이름*"
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                    error={isClick && !name}
                    success={isClick && name}
                    required
                  />
                </div>
                <div>
                  <Select
                    name="gender"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    error={isClick && !gender}
                    success={isClick && gender}
                    required
                  >
                    <option value="">성별*</option>
                    <option value="남자">남자</option>
                    <option value="여자">여자</option>
                  </Select>
                </div>
                <div>
                  <Input
                    placeholder="핸드폰 번호*"
                    name="phone"
                    onChange={(e) => setPhone(e.target.value)}
                    error={isClick && !phone}
                    success={isClick && phone}
                    required
                  />
                </div>
                <div>
                  <Input
                    placeholder="이메일*"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    error={isClick && !email}
                    success={isClick && email}
                    required
                  />
                </div>
                <div>
                  <Select
                    name="age"
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                    error={isClick && !age}
                    success={isClick && age}
                    required
                  >
                    <option value="">연령대*</option>
                    <option value="20대">20대</option>
                    <option value="30대">30대</option>
                    <option value="40대">40대</option>
                    <option value="50대">50대</option>
                    <option value="60대">60대</option>
                  </Select>
                </div>
                {/* <div>
                  <DatePicker
                    name="date"
                    selected={date}
                    locale="ko"
                    className={clsx(
                      "datepicker",
                      isClick && (date ? "success" : "error")
                    )}
                    placeholderText="예상 입주일*"
                    onChange={(date) => setDate(date)}
                    dateFormat="yyyy/MM/dd"
                    required
                  />
                </div> */}
              </InputWrap>
              <AgreeWrap className={isClick && checkbox === false && status}>
                <input
                  type="checkbox"
                  id="checkbox"
                  onChange={() => setCheckbox(!checkbox)}
                  required
                />
                <label for="checkbox">
                  <span>개인정보 수집이용</span>에 동의합니다. (필수)
                </label>
              </AgreeWrap>
            </div>
            <Footer>
              <Button onClick={onSubmit} value="Send">
                상담 예약하기
              </Button>
            </Footer>
          </form>
        </Contents>
      </Dialog>
    </MuiThemeProvider>
  );
};

export default Contract;
