import React from "react";

const InstagramIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      onClick={() => {
        window.location.href = "https://www.instagram.com/youthhouse_d/";
      }}
      style={{ cursor: "pointer" }}
    >
      <g fill="none" fill-rule="evenodd">
        <g>
          <path d="M0 0L32 0 32 32 0 32z" />
          <path
            fill={props.color}
            fill-rule="nonzero"
            d="M16 2.667c3.623 0 4.075.013 5.496.08 1.42.066 2.387.289 3.237.62.88.338 1.622.797 2.363 1.537.678.667 1.203 1.473 1.537 2.363.33.849.554 1.817.62 3.237.063 1.421.08 1.873.08 5.496s-.013 4.075-.08 5.496c-.066 1.42-.29 2.387-.62 3.237-.333.89-.858 1.697-1.537 2.363-.667.678-1.473 1.202-2.363 1.537-.849.33-1.817.554-3.237.62-1.421.063-1.873.08-5.496.08s-4.075-.013-5.496-.08c-1.42-.066-2.387-.29-3.237-.62-.89-.334-1.697-.858-2.363-1.537-.678-.666-1.203-1.473-1.537-2.363-.331-.849-.554-1.817-.62-3.237-.063-1.421-.08-1.873-.08-5.496s.013-4.075.08-5.496c.066-1.421.289-2.387.62-3.237.333-.89.858-1.697 1.537-2.363.666-.678 1.473-1.203 2.363-1.537.85-.331 1.816-.554 3.237-.62 1.421-.063 1.873-.08 5.496-.08zm0 6.666c-3.682 0-6.667 2.985-6.667 6.667s2.985 6.667 6.667 6.667 6.667-2.985 6.667-6.667S19.682 9.333 16 9.333zM24.667 9c0-.92-.747-1.667-1.667-1.667S21.333 8.08 21.333 9s.747 1.667 1.667 1.667S24.667 9.92 24.667 9zM16 12c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4z"
          />
        </g>
      </g>
    </svg>
  );
};

export default InstagramIcon;
