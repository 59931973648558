import { createMuiTheme } from '@material-ui/core'

const DialogTheme = createMuiTheme({
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 0
      }
    },
    MuiDialog: {
      scrollPaper: {
        justifyContent: 'flex-end'
      },
      paper: {
        margin: 0
      },
      paperScrollPaper: {
        maxHeight: '100%',
        minWidth: 360
      }
    }
  }
})

export default DialogTheme