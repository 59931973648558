import React from "react";
import styled from "styled-components";
import Styles from "../assets/theme/styles";
import Colors from "../assets/theme/colors";
import Diagram from "../assets/image/web/about/diagram.png";
import WebHeader from "../assets/image/web/about/about.png";
import MobileHeader from "../assets/image/mobile/about/about.png";
import Header from "../components/Header";
import Infomation from "../components/Infomation";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AboutIcons from "../components/AboutIcons";
import Video from "../assets/image/video.mp4";
import Thumnail from "../assets/image/web/about/youtube-thumbnail.png";

const Contents = styled(Styles.Contents)``;

const Container = styled(Styles.Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.color};

  @media all and (min-width: 641px) {
    padding-top: 200px;
    &:first-child {
      padding-top: 150px;
    }
  }

  @media all and (max-width: 640px) {
    padding-top: 120px;
    &:first-child {
      padding-top: 95px;
    }
  }
`;

const Paper = styled(Styles.Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Styles.Title)`
  margin-bottom: 44px;
`;

const Description = styled(Styles.Description)``;

const ValueBoxWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 1315px;

  @media all and (min-width: 641px) {
    flex-basis: 30%;
    margin-top: 120px;
    margin-bottom: 152px;
  }

  @media all and (max-width: 640px) {
    flex-basis: 50%;
    margin-top: 80px;
    margin-bottom: 80px;
  }
`;

const ValueBox = styled.div`
  display: flex;
  background-color: ${Colors.green};
  color: ${Colors.white};
  box-sizing: border-box;
  flex-direction: column;

  @media all and (min-width: 641px) {
    width: 405px;
    height: 351px;
    margin-bottom: 46px;
    padding: 38px 30px;
    justify-content: flex-end;
  }

  @media all and (max-width: 640px) {
    width: 152px;
    margin-bottom: 16px;
    padding: 27px 12px 30px 12px;
    justify-content: flex-start;
  }
`;

const ValueIcon = styled.div`
  display: flex;
  @media all and (min-width: 641px) {
    margin-bottom: 23px;
    align-items: flex-end;
  }

  @media all and (max-width: 640px) {
    height: 40px;
    align-items: flex-start;
    margin-bottom: 14px;
    img {
      height: 60%;
    }
  }
`;

const ValueTitle = styled.div`
  font-family: "GmarketSans", sans-serif;
  font-weight: 500;
  line-height: 1.7;

  @media all and (min-width: 641px) {
    margin-bottom: 40px;
    font-size: 20px;
  }

  @media all and (max-width: 640px) {
    height: 68px;
    font-size: 15px;
    word-break: keep-all;
  }
`;

const ValueDescription = styled.div`
  font-family: "NotoSans", sans-serif;
  font-weight: 300;
  word-break: keep-all;

  @media all and (min-width: 641px) {
    font-size: 14px;
    min-height: 63px;
  }

  @media all and (max-width: 640px) {
    font-size: 12px;
  }
`;

const DiagramWrap = styled.div`
  max-width: 1123px;
  img {
    width: 100%;
  }

  @media all and (min-width: 641px) {
    margin-top: 120px;
    margin-bottom: 182px;
  }

  @media all and (max-width: 640px) {
    margin-top: 50px;
    margin-bottom: 80px;
  }
`;

const VideoBox = styled.div`
  max-width: 852px;
  max-height: 479px;
  video {
    width: 100%;
    outline: none;
    border: none;
  }

  @media all and (min-width: 641px) {
    margin-top: 76px;
    margin-bottom: 200px;
  }

  @media all and (max-width: 640px) {
    margin-bottom: 80px;
  }
`;

const About = ({ contract }) => {
  const isMobile = useMediaQuery("(max-width: 640px)");
  return (
    <Contents>
      <Header
        color={Colors.black}
        mobileColor={Colors.white}
        bg={{ image: WebHeader, height: 499 }}
        mobileBg={{ image: MobileHeader, height: 259 }}
        contract={contract}
      />
      <Container color={Colors.bg_grey}>
        <Paper>
          <Title>대전청년하우스의 목표</Title>
          <Description>
            대전청년하우스는 대전 청년들만을 위한 Social Apartment로,
            <br />
            청년근로자의 주거안정 및 주거수준향상과 대전 청년문화 활성화를 위해
            노력합니다.
            <br />
            개인 주거 공간은 물론 여럿이 사용하는 공간을 통한 새로운 주거 문화를
            누려보세요.
          </Description>
          <DiagramWrap>
            <img src={Diagram} alt="Diagram" />
          </DiagramWrap>
        </Paper>
      </Container>
      <Container color="white">
        <Paper>
          <Title>대전청년하우스의 가치</Title>
          <Description>
            개성과 취향이 존중되면서 함께 나누고 함께 누리는 Urban lifestyle,
            <br />
            대전청년하우스에서 만나실 수 있습니다.
          </Description>
          <ValueBoxWrap>
            <ValueBox>
              <ValueIcon>
                <AboutIcons id="social" width={isMobile && "29"} />
              </ValueIcon>
              <ValueTitle>소셜 네트워크 형성</ValueTitle>
              <ValueDescription>
                다양한 직업군의 청년들과 함께 거주하며
                <br />
                인적 네트워크 형성 및 다양한 지식, 경험 교류가
                <br />
                가능합니다.
              </ValueDescription>
            </ValueBox>
            <ValueBox>
              <ValueIcon>
                <AboutIcons id="service" width={isMobile && "22"} />
              </ValueIcon>
              <ValueTitle>생활에 편리함을 더해주는 서비스</ValueTitle>
              <ValueDescription>
                보안 시스템, 무인 편의점, 무인 택배함, 카셰어링 등 다양한
                서비스를 제공합니다.
              </ValueDescription>
            </ValueBox>
            <ValueBox>
              <ValueIcon>
                <AboutIcons id="joy" width={isMobile && "25"} />
              </ValueIcon>
              <ValueTitle>일상을 보다 즐겁고 다채롭게</ValueTitle>
              <ValueDescription>
                운동, 공방, 메이킹 등 다양한 원데이 클래스로 평소
                <br />
                경험할 수 없는 다양한 이색체험 활동들을 기획하여 운영합니다.
              </ValueDescription>
            </ValueBox>
            <ValueBox>
              <ValueIcon>
                <AboutIcons id="phone" width={isMobile && "15"} />
              </ValueIcon>
              <ValueTitle>스마트 하우스</ValueTitle>
              <ValueDescription>
                복잡한 월세, 관리비 납부를&nbsp;&nbsp;'그라운드노트'&nbsp;&nbsp;
                <br />
                앱으로 스마트하게 해결할 수 있습니다.
              </ValueDescription>
            </ValueBox>
            <ValueBox>
              <ValueIcon>
                <AboutIcons id="house" width={isMobile && "28"} />
              </ValueIcon>
              <ValueTitle>최적의 입지 조건</ValueTitle>
              <ValueDescription>
                현대 프리미엄아울렛, 신세계백화점, 엑스포,
                <br />
                DCC, 한빛탑 등 대전 대표명소 및<br />
                대형쇼핑몰과 근접한 곳에 위치해 있습니다.
              </ValueDescription>
            </ValueBox>
            <ValueBox>
              <ValueIcon>
                <AboutIcons id="coin" width={isMobile && "20"} />
              </ValueIcon>
              <ValueTitle>합리적인 비용</ValueTitle>
              <ValueDescription>
                저렴한 보증금과 월세로 부담없이 쾌적한 환경을
                <br />
                누릴 수 있습니다.
              </ValueDescription>
            </ValueBox>
          </ValueBoxWrap>
        </Paper>
      </Container>
      <Container color={Colors.bg_grey}>
        <Paper>
          <Title>홍보영상</Title>
          <VideoBox>
            <video poster={Thumnail} controls playsinline>
              <source src={Video} />
            </video>
          </VideoBox>
        </Paper>
      </Container>
      <Container color={Colors.bg_bluegrey}>
        <Paper>
          <Title>대전청년하우스 자세히 보기</Title>
          <Infomation />
        </Paper>
      </Container>
    </Contents>
  );
};

export default About;
