import React from "react";
import styled from "styled-components";
import Colors from "../assets/theme/colors";
import Slider from "react-slick";
import LeftArrowIcon from "../assets/image/svg/arrow-left.svg";
import RightArrowIcon from "../assets/image/svg/arrow-right.svg";
import Facility01 from "../assets/image/web/house/facility/lounge.jpg";
import Facility02 from "../assets/image/web/house/facility/study-lounge.jpg";
import Facility03 from "../assets/image/web/house/facility/relax-room.jpg";
import Facility04 from "../assets/image/web/house/facility/kitchen.jpg";
import Facility05 from "../assets/image/web/house/facility/kitchen2.jpg";
import Facility06 from "../assets/image/web/house/facility/laundry-room.jpg";
import Facility07 from "../assets/image/web/house/facility/fitness.jpg";
// import Facility08 from '../assets/image/web/house/facility/08.jpg'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Arrow = styled.div`
  @media all and (min-width: 1100px) {
    cursor: pointer;
    position: absolute;
    top: calc(50% - 35px);
    width: 70px;
    height: 70px;
    background-color: ${Colors.black};
    background-image: url(${(props) =>
      props.left ? LeftArrowIcon : RightArrowIcon});
    background-size: 30px 30px;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media all and (max-width: 640px) {
    display: none;
  }
`;

const SliderWrap = styled.div`
  position: relative;
  width: 100vw;

  .slick-track {
    overflow-y: hidden;

    .slick-slide {
      opacity: 0.75;
    }
    .slick-active {
      opacity: 1;
    }
  }

  .slick-slide {
    z-index: 500;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
  }

  .slick-dots {
    bottom: -20px;
    li {
      width: fit-content;
      height: fit-content;
      margin: 0 9px 0 0;
      &:last-child {
        margin: 0;
      }
    }
  }

  .slick-active {
    button:before {
      background: ${Colors.white};
    }
  }

  .slick-dots li button {
    width: 7px;
    height: 7px;
    &:before {
      content: "";
      display: inline-block;
      width: 7px;
      height: 7px;
      color: ${Colors.green};
      border: 1px solid ${Colors.white};
      border-radius: 50%;
      opacity: 1;
    }
  }

  @media all and (min-width: 1681px) {
    .left {
      left: 4vw;
    }
    .right {
      right: 4vw;
    }
  }

  @media all and (max-width: 1680px) and (min-width: 1441px) {
    .left {
      left: 4vw;
    }
    .right {
      right: 4vw;
    }
  }

  @media all and (max-width: 1440px) and (min-width: 641px) {
    .left {
      left: 2vw;
    }
    .right {
      right: 2vw;
    }
  }
`;

const SliderItem = styled.div`
  img {
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
  &:focus {
    border: 0;
    outline: 0;
  }
`;

const Facility = () => {
  const serviceSlider = React.createRef();

  const gotoNext = () => {
    serviceSlider.current.slickNext();
  };

  const gotoPrev = () => {
    serviceSlider.current.slickPrev();
  };

  const facility = [
    Facility01,
    Facility02,
    Facility03,
    Facility04,
    Facility05,
    Facility06,
    Facility07,
  ];

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 3,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          dots: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          dots: true,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <SliderWrap>
      <Slider {...settings} ref={serviceSlider}>
        {facility.map((num, i) => (
          <SliderItem key={i}>
            <img src={facility[i]} alt="Room Type" />
          </SliderItem>
        ))}
      </Slider>
      <Arrow left className="left" onClick={() => gotoPrev()} />
      <Arrow right className="right" onClick={() => gotoNext()} />
    </SliderWrap>
  );
};

export default Facility;
