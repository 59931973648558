import React, { useState } from 'react'
import styled from 'styled-components'
import Colors from '../assets/theme/colors'
import Collapse from '@material-ui/core/Collapse'
import DownIcon from '../assets/image/svg/down.svg'

const Info = styled.div`
  &>* {
    font-family: 'GmarketSans', sans-serif;
  }
  &:first-child {
    border-top: 1px solid #404040;
  }
  border-bottom: 1px solid #c9c9c9;

  @media all and (min-width: 641px) {

  }

  @media all and (max-width: 640px) {

  }
`

const InfoTitle = styled.div`
  cursor: pointer;
  color: ${props => props.toggle ? Colors.green : Colors.black};
  display: flex;
  justify-content: space-between;
  align-items: center;

  img.up {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  @media all and (min-width: 641px) {
    padding: 35px;
    font-size: 18px;
  }

  @media all and (max-width: 640px) {
    padding: 20px;
    font-size: 15px;
  }
`

const InfoContent = styled.div`
  border-top: 1px solid #c9c9c9;

  @media all and (min-width: 641px) {
    padding: 70px 35px;
  }

  @media all and (max-width: 640px) {
    padding: 35px 20px;
  }
`

const InfoList = styled.ul`
  margin: 0 0 0 20px;
  padding: 0;
  li:first-child {
    padding-top: 0;
    
  }
  li:last-child {
    padding-bottom: 0;

  }

  @media all and (min-width: 641px) {


  }

  @media all and (max-width: 640px) {

  }
`

const InfoDescription = styled.li`
  color: ${Colors.black};
  font-weight: 500;

  @media all and (min-width: 641px) {
    padding: 15px 0;
    font-size: 14px;
  }

  @media all and (max-width: 640px) {
    padding: 5px 0;
    font-size: 10px;
  }
`


const Faq = ({ info }) => {
  const [toggle, setToggle] = useState(false)

  return (
    <Info>
      <InfoTitle toggle={toggle} onClick={() => setToggle(!toggle)}>
        {info.title}
        <img src={DownIcon} alt={toggle ? 'Up' : 'Down'} className={toggle ? 'up' : 'down'} />
      </InfoTitle>
      <Collapse in={toggle}>
        <InfoContent>
          <InfoList>
            {
              info.content.map((content, i) => 
                <InfoDescription key={i}>
                  {
                    content.text.split('\n').map((text, index) => <div key={index}>{text}</div>)
                  }
                </InfoDescription>
              )
            }
          </InfoList>
        </InfoContent>
      </Collapse>
    </Info>
  )
}

export default Faq
