import React from 'react'

const CloseIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
      <g fill="none" fill-rule="evenodd">
          <g fill={props.color}>
              <g>
                  <path d="M22.5 8.813L21.188 7.5 15 13.688 8.813 7.5 7.5 8.813 13.688 15 7.5 21.188 8.813 22.5 15 16.313 21.188 22.5 22.5 21.188 16.313 15z" transform="translate(-313 -7) translate(313 7)"/>
              </g>
          </g>
      </g>
    </svg>
  )
}

export default CloseIcon
