import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import Styles from "../assets/theme/styles";
import Colors from "../assets/theme/colors";
import Slider from "react-slick";
import animateScrollTo from "animated-scroll-to";
import Header from "../components/Header";
import FAQ from "../components/Faq";
import Information from "../components/Infomation";
import Program from "../components/Program";
import DownArrow from "../assets/image/svg/arrow-down.svg";
import Background01 from "../assets/image/web/main/01.png";
import Background02 from "../assets/image/web/main/02.png";
import BackgroundMobile01 from "../assets/image/mobile/main/01.png";
import BackgroundMobile02 from "../assets/image/mobile/main/02.png";
import Detail01 from "../assets/image/web/main/detail_01.png";
import Detail02 from "../assets/image/web/main/detail_02.png";
import AboutBtn from "../assets/image/web/main/aboutBtn.png";
import HouseBtn from "../assets/image/web/main/houseBtn.png";
import CommunityBtn from "../assets/image/web/main/communityBtn.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Contents = styled(Styles.Contents)`
  overflow: hidden;
`;

const Container = styled(Styles.Container)`
  background-color: ${(props) => props.color};

  @media all and (min-width: 641px) {
    ${(props) =>
      props.Program &&
      css`
        padding-top: 200px;
        padding-bottom: 50px;
        padding-left: 3vw;
        padding-right: 3vw;
      `}
    ${(props) =>
      props.FAQ &&
      css`
        padding-top: 206px;
        padding-bottom: 205px;
      `}
  }

  @media all and (max-width: 1023px) {
    ${(props) =>
      props.Program &&
      css`
        padding-left: 20px;
        padding-right: 20px;
      `}
  }

  @media all and (max-width: 640px) {
    ${(props) =>
      props.Program &&
      css`
        padding-top: 80px;
      `}
    ${(props) =>
      props.FAQ &&
      css`
        padding-top: 80px;
        padding-bottom: 80px;
      `}
  }
`;

const Paper = styled(Styles.Paper)`
  margin-left: auto;
  margin-right: auto;
  ${(props) =>
    props.left &&
    css`
      width: fit-content;
      align-items: flex-start;
    `}

  @media all and (min-width: 641px) {
    padding: ${(props) => props.Map && "251px 0 33px"};
    ${(props) =>
      props.Program &&
      css`
        width: 100%;
        max-width: 1315px;
      `}
  }

  @media all and (max-width: 640px) {
    padding: ${(props) => props.Map && "80px 0 79px"};
    margin: ${(props) => props.Program && "0"};
  }
`;

const MainContainer = styled(Styles.Container)`
  height: 100vh;
  padding: 0;
  position: relative;

  @media all and (min-width: 641px) {
  }

  @media all and (max-width: 640px) {
  }
`;

const ImageContainer = styled(Styles.Container)`
  padding: 0;
  ${(props) =>
    props.Detail02 &&
    css`
      background-color: ${Colors.green};
      color: white;
    `}
`;

const ImagePaper = styled(Styles.Paper)`
  position: relative;
  display: flex;
  align-items: initial;

  @media all and (min-width: 641px) {
    flex-direction: row;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    ${(props) =>
      props.Detail01 &&
      css`
        padding: 177px 0;
        justify-content: flex-end;
      `}
    ${(props) =>
      props.Detail02 &&
      css`
        padding: 184px 0 183px 0;
        justify-content: flex-start;
      `}
  }

  @media all and (max-width: 640px) {
    ${(props) =>
      props.Detail01 &&
      css`
        flex-direction: column;
        padding: 80px 0;
        background-color: white;
      `}
    ${(props) =>
      props.Detail02 &&
      css`
        flex-direction: column-reverse;
        padding: 110px 0;
      `}
  }
`;

const RoundFillBtn = styled(Styles.RoundBtn)`
  color: ${Colors.green};
  background-color: white;
  &::before {
    content: "입주 문의하기";
  }

  @media all and (min-width: 641px) {
    margin-right: 16px;
  }

  @media all and (max-width: 640px) {
    margin-right: 10px;
  }
`;

const RoundLineBtn = styled(Styles.RoundBtn)`
  color: white;
  background-color: rgba(0, 0, 0, 0);
  &::before {
    content: "둘러보러 가기";
  }
`;

const ScrollDownBtn = styled(Styles.Button)`
  position: absolute;
  cursor: pointer;
  border-radius: 50%;
  flex-direction: column;
  font-weight: 500;
  &::before {
    content: "Scroll";
    margin-bottom: 4px;
  }
  &::after {
    content: "";
    background: url(${DownArrow});
    background-position: center;
  }

  @media all and (min-width: 641px) {
    width: 88px;
    height: 88px;
    font-size: 15px;
    bottom: 10px;
    left: 43.5%;
    &::after {
      width: 20px;
      height: 20px;
    }
  }

  @media all and (max-width: 640px) {
    width: 74px;
    height: 74px;
    font-size: 12px;
    bottom: -13px;
    left: 50%;
    transform: translate(-50%);
    &::after {
      width: 16px;
      height: 16px;
    }
  }
`;

const ColorBox = styled.div`
  background-color: ${(props) => (props.green ? Colors.green : "white")};

  @media all and (min-width: 641px) {
    box-sizing: border-box;
    display: inline-block;
    height: 100%;
    ${(props) =>
      props.green &&
      css`
        width: 57%;
      `}
    ${(props) =>
      props.white &&
      css`
        width: 43%;
      `}
  }

  @media all and (man-width: 640px) {
    display: none;
  }
`;

const MainBanner = styled.div`
  display: flex;

  @media all and (min-width: 1921px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media all and (min-width: 641px) {
    position: absolute;
    top: 106px;
    bottom: 107px;
    left: 0;
    right: 0;
    justify-content: flex-end;
    max-width: 1810px;
  }

  @media all and (max-width: 640px) {
    justify-content: center;
    height: 100%;
  }
`;

const MainTextBox = styled.div`
  height: fit-content;

  @media all and (min-width: 641px) {
    padding-top: 9.2vh;
    padding-right: 120px;
    z-index: 10;
  }

  @media all and (max-height: 760px) {
    padding-top: 0;
  }

  @media all and (max-width: 640px) {
    padding-top: 15vh;
  }
`;

const MainDescription = styled.div`
  color: ${Colors.white};

  @media all and (min-width: 641px) {
    font-size: 20px;
    letter-spacing: 2.8px;
    margin-bottom: 63px;
  }

  @media all and (max-width: 640px) {
    font-size: 12px;
    letter-spacing: 0.86px;
    margin-bottom: 32px;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled.div`
  font-family: "GmarketSans", sans-serif;
  color: white;
  font-weight: 300;
  line-height: 1.4;

  @media all and (min-width: 641px) {
    font-size: 95px;
    margin-bottom: 46px;
  }

  @media all and (max-width: 640px) {
    font-size: 48px;
    margin-bottom: 16px;
  }
`;

const UnderLine = styled.span`
  font-family: "GmarketSans", sans-serif;

  @media all and (min-width: 641px) {
    ${(props) =>
      props.active &&
      css`
        padding-bottom: ${(props) => (props.text === "01" ? "6px" : "4px")};
        border-bottom: 4px solid white;
      `}
  }

  @media all and (max-width: 640px) {
  }
`;

const NumberWrap = styled(ButtonWrap)`
  @media all and (min-width: 641px) {
    align-items: flex-end;
    position: absolute;
    left: 8.3vw;
    bottom: 35px;
  }

  @media all and (max-width: 640px) {
    display: none;
  }
`;

const ChangeBtn = styled.div`
  cursor: pointer;
  font-family: "GmarketSans", sans-serif;
  opacity: 0.9;
  &:first-child {
    margin-right: 7px;
  }
  ${(props) =>
    !props.active &&
    css`
      width: 68px;
      border-bottom: 1px solid white;
      margin-bottom: 2px;
    `}
  ${(props) =>
    props.active &&
    css`
      width: 69px;
      border-bottom: 5px solid white;
      padding-bottom: 12px;
      &::before {
        font-size: 18px;
        font-weight: bold;
        color: white;
        content: "${(props) => props.text}";
      }
    `}
`;

const Text = styled(Title)`
  color: ${(props) => props.color};
  margin: 0;

  @media all and (min-width: 641px) {
    font-size: 40px;
    margin-bottom: 26px;
    ${(props) =>
      props.Program &&
      css`
        font-size: 32px;
        margin-bottom: 38px;
      `}
  }

  @media all and (max-width: 640px) {
    font-size: 26px;
    margin-bottom: 13px;
    ${(props) =>
      props.Program &&
      css`
        font-size: 18px;
        margin-bottom: 18px;
      `}
  }
`;

const SubTitle = styled.div`
  font-family: "GmarketSans", sans-serif;
  color: ${(props) => props.color};
  font-weight: 500;
  line-height: 1.4;
  span {
    font-family: "GmarketSans", sans-serif;
    color: ${Colors.green};
  }

  @media all and (min-width: 641px) {
    font-size: 40px;
    margin: ${(props) =>
      !props.Map && !props.FAQ && !props.Program && "0 0 70px 0"};
    margin-bottom: ${(props) => props.FAQ && "95px"};
  }

  @media all and (max-width: 640px) {
    font-size: 26px;
    margin: 0 0 50px 0;
    ${(props) =>
      props.Program &&
      css`
        margin: 0;
        color: ${Colors.green};
        word-break: keep-all;
      `}
    ${(props) =>
      props.FAQ &&
      css`
        margin-bottom: 55px;
        max-width: 290px;
      `}
    ${(props) =>
      props.Map &&
      css`
        max-width: 176px;
      `}
  }
`;

const Description = styled(Styles.Description)`
  text-align: left;
  color: ${(props) => (props.Detail02 ? Colors.white : Colors.black)};
  line-height: 1.7;

  @media all and (min-width: 641px) {
  }

  @media all and (max-width: 640px) {
    ${(props) =>
      props.Detail02 &&
      css`
        opacity: 0.9;
      `}
  }
`;

const TextBox = styled.div`
  @media all and (min-width: 641px) {
    width: fit-content;
    height: fit-content;
    min-width: 450px;
    ${(props) =>
      props.Detail01 &&
      css`
        padding-left: 6vw;
        padding-right: 12vw;
      `}
    ${(props) =>
      props.Detail02 &&
      css`
        padding-left: 6.5vw;
        padding-right: 7vw;
      `}
  }

  @media all and (max-width: 640px) {
    padding: 0 20px;
  }
`;

const ArrowBtn = styled.div`
  cursor: pointer;
  display: inline-block;
  img {
    width: 100%;
    height: auto;
  }

  @media all and (min-width: 641px) {
    margin-top: 80px;
    ${(props) =>
      props.About &&
      css`
        width: 183px;
      `}
    ${(props) =>
      props.House &&
      css`
        width: 235px;
      `}
    ${(props) =>
      props.Program &&
      css`
        width: 247px;
      `}
  }

  @media all and (max-width: 640px) {
    margin-top: 40px;
    ${(props) =>
      props.About &&
      css`
        width: 128px;
      `}
    ${(props) =>
      props.House &&
      css`
        width: 168px;
      `}
    ${(props) =>
      props.Program &&
      css`
        width: 180px;
      `}
  }
`;

const Image = styled.div`
  @media all and (min-width: 641px) {
    width: 100%;
    background: url(${(props) => props.bg}) no-repeat;
    background-size: cover;
    img {
      display: none;
    }
    ${(props) =>
      props.Detail01 &&
      css`
        max-width: 1023px;
        height: 682px;
      `}
    ${(props) =>
      props.Detail02 &&
      css`
        max-width: 868px;
        height: 639px;
        background-position: center;
      `}
  }

  @media all and (max-width: 640px) {
    min-width: 340px;
    min-height: 198px;
    margin-top: 60px;
    img {
      width: 100%;
      height: auto;
    }
    ${(props) =>
      props.Detail01 &&
      css`
        margin-left: 20px;
      `}
    ${(props) =>
      props.Detail02 &&
      css`
        margin-right: 20px;
      `}
  }
`;

const FaqWrapper = styled.div`
  @media all and (min-width: 641px) {
    width: 852px;
  }

  @media all and (max-width: 640px) {
    width: 100%;
  }
`;

const SliderWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  div {
    height: 100%;
  }
  button {
    display: none !important;
  }

  @media all and (max-width: 640px) {
    z-index: -1;
  }
`;

const SliderItem = styled.div`
  &:focus {
    border: 0;
    outline: 0;
  }

  @media all and (min-width: 641px) {
    background: url(${(props) => props.bg}) no-repeat;
    background-position: center;
    background-size: cover;
  }

  @media all and (max-width: 640px) {
    background: url(${(props) => props.mobilebg}) no-repeat;
    background-position: center;
    background-size: cover;
  }
`;

const Guide = [
  {
    title: "입주자 생활이 궁금해요",
    content: [
      {
        text: "청년하우스 내 흡연구역을 제외한 모든 공간은 금연입니다.",
      },
      {
        text: "반려동물은 종류와 크기에 상관없이 동반 입주가 불가합니다.",
      },
      {
        text: "쓰레기 및 분리수거\n- 분리수거 시에는 재활용의 부피를 최소화하여 주세요.\n- 음식물이 있는 비닐, 페트병 등을 세척 후 버려주세요.",
      },
      {
        text: "대전청년하우스에서 정한 입주관리 및 안전 규정을 준수해야 합니다.",
      },
      {
        text: "상해, 질병 등에 대해 예방 및 치료할 수 있도록 조치하여야 하며 필요시 관리 사무실에 도움 등을 요청할 수 있습니다.\n또한 감염병 예방을 위해 적극 협조하여야 하며 입주자 스스로 보건 관리에 철저를 기하여야 합니다.",
      },
      {
        text: "시설물과 설비 등 대전청년하우스 직원이 관리함에 있어 적극 협조해야 하며,\n개인의 과실 및 고의로 인한 시설물과 설비의 고장 및 파손 등에 대하여는 개인에게 책임이 있습니다.",
      },
      {
        text: "고성방가, 폭행, 추행 등의 행위를 하였을 경우 강제 퇴실 될 수 있습니다.",
      },
      {
        text: "객실 내에서의 취사행위는 불가하며, 배달음식 서비스는 이용 가능합니다.\n(단, 배달음식의 경우 배달원은 현관까지만 통과할 수 있으니 현관에서 배달음식을 수취하시기 바랍니다.)",
      },
      {
        text: "택배는 1층 무인택배함에서 수취 가능하며, 택배함이 부족하거나 부피가 큰 택배 및 등기우편은 안내실에 사전 문의 후 수취하시기 바랍니다. (다만, 개인 및 택배사의 부주의로 인한 파손 및 분실의 경우 책임지지 않습니다.)",
      },
    ],
  },
  {
    title: "공용공간 생활이 궁금해요",
    content: [
      {
        text: "공용공간 내 모든 시설 및 물품들은 내 것처럼 소중히 사용해 주세요.",
      },
      {
        text: "청년하우스 내 모든 물품은 외부로 반출이 불가합니다.",
      },
      {
        text: "각 공용공간 이용 시 주의사항을 잘 지켜주세요.",
      },
      {
        text: "음식 섭취는 객실 외에 스낵바에서만 가능하며, 음식 섭취 시 무분별한 쓰레기 배출, 고성방가 및 취사행위를 금합니다.\n음주는 객실 외 건물 모든 공간에서 불가합니다.",
      },
      {
        text: "환경 보호를 위하여 여름/겨울철 실내 온도는 적정 수준으로 유지됩니다.",
      },
      {
        text: "공용공간 사용 시 다른 이용자에게 피해를 줄 수 있는 행위와 소음 및 무분별한 쓰레기 배출에 주의해 주세요.",
      },
      {
        text: "공용공간에 무단으로 집기 및 개인 물품을 방치하지 말아주세요.",
      },
      {
        text: "반려동물의 공용공간 출입은 불가합니다.",
      },
      {
        text: "낙서, 유인물, 부착물 등의 무단 게시 및 배포와 상업 행위를 금지합니다.",
      },
      {
        text: "사고 및 비상상황 발생 시 운영 매니저에게 연락 바랍니다.",
      },
      {
        text: "여성전용 공용공간 사용 시 이성의 출입을 금합니다.",
      },
    ],
  },
  {
    title: "방문객은 어떻게 이용하나요?",
    content: [
      {
        text: "청년하우스 출입 시 신분증을 제출해야만 방문 카드 수령이 가능하며,\n방문 카드는 이용 후에 반납 부탁드립니다.",
      },
      {
        text: "청년하우스 내 흡연구역을 제외한 모든 공간은 금연입니다.",
      },
      {
        text: "입주민 전용 공용공간은 출입하실 수 없습니다.",
      },
      {
        text: "위험물품 소지 시 청년하우스 출입이 불가합니다.",
      },
      {
        text: "사고 및 비상상황 발생 시 운영매니저에게 연락 바랍니다.",
      },
    ],
  },
  {
    title: "외부인 숙박은 어떻게 해야 하나요?",
    content: [
      {
        text: "만 18세 이상의 외부인만 숙박 가능합니다.",
      },
      {
        text: "외부인은 방문 시 신분증 제출 등 신분확인 절차 통하여 출입증을 받으실 수 있습니다.",
      },
      {
        text: "퇴실 시 출입증을 반납하여 주시고 신분증을 수령해 주세요.",
      },
      {
        text: "여성 전용 층에는 여성 외부인만 숙박이 가능합니다.",
      },
      {
        text: "외부인은 입주민 전용 공용 공간에 출입할 수 없습니다.",
      },
      {
        text: "무단 숙박 및 과도한 소음 발생 등 입주민들에게 피해가 갈 수 있는 행동 시 강제 퇴실 등 강력한 조치를 취할 수 있으니\n유의하시기 바랍니다.",
      },
      {
        text: "대전청년하우스 내 흡연구역을 제외한 모든 공간은 금연입니다.",
      },
    ],
  },
  {
    title: "생활수칙 패널티가 있나요?",
    content: [
      {
        text: "입주자 생활수칙을 위반할 경우 최대 3회 구두 경고 이후 퇴거 기준에 의거하여 퇴실 처리됩니다.",
      },
      {
        text: "공용공간 이용수칙을 위반할 경우 2회까지 구두 경고, 3회부터 공용공간에서 진행하는 행사 및 커뮤니티 참여가 불가합니다.",
      },
      {
        text: "외부인 출입 및 숙박 수칙을 위반할 경우 1회 1개월간 외부인 출입 금지, 2회 3개월간 외부인 출입 금지,\n3회 영구 출입 금지 처리됩니다.",
      },
      {
        text: "여성 전용층에 남성 외부인을 무단으로 숙박 시켰을 경우 바로 강제 퇴실 조치됩니다.",
      },
    ],
  },
];

const Home = ({ contract, isPopup }) => {
  const [activeBg, setActiveBg] = useState(true);
  const backgroundImage = [Background01, Background02];
  const backgroundMobileImage = [BackgroundMobile01, BackgroundMobile02];

  const slider = React.createRef();

  const gotoNext = (current) => {
    if (current !== activeBg) {
      slider.current.slickNext();
      setActiveBg(!activeBg);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    arrow: false,
    variableHeight: true,
    draggable: false,
  };

  useEffect(() => {
    const changeBackground = setInterval(gotoNext, 10000);
    return (_) => {
      clearInterval(changeBackground);
    };
  });

  const onClick = () => {
    animateScrollTo(document.querySelector("#contents"));
  };

  useEffect(() => {
    const location = window.location;
    if (
      location &&
      location.pathname &&
      location.pathname.indexOf("faq") > -1
    ) {
      let pos = document.querySelector("#faq").offsetTop;
      window.scrollTo({ top: pos, behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (isPopup) {
      window.open("/popup", "", "width=500, height=740");
    }
  }, [isPopup]);

  return (
    <Contents>
      <Header
        position="absolute"
        color="white"
        mobileColor="white"
        contract={contract}
      />
      <MainContainer Main>
        <ColorBox white />
        <ColorBox green />
        <MainBanner>
          <SliderWrap>
            <Slider {...settings} ref={slider}>
              {backgroundImage.map((num, i) => (
                <SliderItem
                  key={i}
                  bg={backgroundImage[i]}
                  mobilebg={backgroundMobileImage[i]}
                />
              ))}
            </Slider>
          </SliderWrap>
          <NumberWrap>
            <ChangeBtn
              text="01"
              active={activeBg === true}
              onClick={() => gotoNext(true)}
            />
            <ChangeBtn
              text="02"
              active={activeBg === false}
              onClick={() => gotoNext(false)}
            />
          </NumberWrap>
          <MainTextBox>
            <Title>
              <UnderLine active={activeBg === true} text="01">
                따로,
              </UnderLine>
              <br />
              <UnderLine active={activeBg === false} text="02">
                또 같이
              </UnderLine>
            </Title>
            <MainDescription>
              새로운 주거 문화의 시작. 대전청년하우스
            </MainDescription>
            <ButtonWrap>
              <RoundFillBtn onClick={() => contract && contract(true)} />
              <RoundLineBtn onClick={onClick} />
            </ButtonWrap>
          </MainTextBox>
        </MainBanner>
        <ScrollDownBtn onClick={onClick} />
      </MainContainer>
      <ImageContainer id="contents">
        <ImagePaper Detail01>
          <TextBox Detail01>
            <Text color={Colors.black}>
              청년들을 위한
              <br />
              Social Apartment
            </Text>
            <SubTitle color={Colors.green}>새로운 주거 문화의 시작</SubTitle>
            <Description>
              청년근로자의 주거안정 및 주거수준향상과
              <br />
              대전 청년문화 활성화를 위한 대전청년하우스에서
              <br />
              함께 새로운 주거 문화를 누려보세요
            </Description>
            <ArrowBtn About>
              <Link to="/about">
                <img src={AboutBtn} alt="About" />
              </Link>
            </ArrowBtn>
          </TextBox>
          <Image Detail01 bg={Detail01}>
            <img src={Detail01} alt="image01" />
          </Image>
        </ImagePaper>
      </ImageContainer>
      <ImageContainer Detail02>
        <ImagePaper Detail02>
          <Image Detail02 bg={Detail02}>
            <img src={Detail02} alt="image02" />
          </Image>
          <TextBox Detail02>
            <SubTitle color="white">
              층별로 다양한 편의시설과
              <br />
              나만의 쾌적한 공간
            </SubTitle>
            <Description Detail02>
              실용적인 가구들로 이루어진 객실과
              <br />
              여럿이 이용해도 쾌적한 공용 공간들을
              <br />
              지금 바로 구경해보세요
            </Description>
            <ArrowBtn House>
              <Link to="/house">
                <img src={HouseBtn} alt="House" />
              </Link>
            </ArrowBtn>
          </TextBox>
        </ImagePaper>
      </ImageContainer>
      <Container Program color={Colors.bg_bluegrey}>
        <Paper Program left>
          <Text Program color={Colors.black}>
            당신의 ON이 소중한 만큼,
            <br />
            당신의 OFF도 소중하니까
          </Text>
          <SubTitle Program color={Colors.black}>
            나만의 편안한 공간은 물론
            <br />
            함께 즐기는 커뮤니티 프로그램까지!
          </SubTitle>
          <ArrowBtn Program>
            <Link to={{ pathname: `/community`, state: "progPro" }}>
              <img src={CommunityBtn} alt="community" />
            </Link>
          </ArrowBtn>
        </Paper>
        <Paper>
          <Program />
        </Paper>
      </Container>
      {/* 해당 컨테이넌의 id값이 원래는 아래 컨테이너에 있었음.*/}
      <Container FAQ id="faq">
        <Paper left FAQ>
          <SubTitle FAQ color={Colors.black}>
            궁금한 점이 있으신가요?
            <br />
            <span>청년하우스 생활 가이드</span>를 확인해보세요
          </SubTitle>
          <FaqWrapper>
            {Guide.map((Guide, i) => (
              <FAQ info={Guide} key={i} />
            ))}
          </FaqWrapper>
        </Paper>
      </Container>
      <Container color={Colors.bg_grey}>
        <Paper Map left>
          <SubTitle color={Colors.black} Map>
            대전청년하우스 자세히 보기
          </SubTitle>
          <Information />
        </Paper>
      </Container>
    </Contents>
  );
};

export default Home;
