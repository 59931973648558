import React from 'react'

const MenuIcon = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fill-rule="evenodd">
        <g fill={props.color}>
          <g>
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" transform="translate(-316 -10) translate(316 10)"/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default MenuIcon
