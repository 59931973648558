import styled from 'styled-components'
import Colors from '../theme/colors'
import CaretDown from '../image/svg/caret-down.svg'

const Contents = styled.div`
  width: 100%;
  min-width: 360px;

  @media all and (min-width: 641px) {
    min-width: 1024px;
    overflow: hidden;
  }
`

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;

  @media all and (max-width: 640px) {
    max-width: 640px;
  }
`

const GreenContainer = styled(Container)`
  background-color: ${Colors.green};
`

const Paper = styled.div`
  max-width: 1920px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Button = styled.div`
  cursor: pointer;
  color: white;
  background-color: ${Colors.green};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'GmarketSans', sans-serif;
`

const ContactBtn = styled.div`
  cursor: pointer;
  border-radius: 50%;
  font-weight: 500;
  color: ${Colors.green};
  background-color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'GmarketSans', sans-serif;
  &:hover {
    color: white;
    background-color: ${Colors.green};
  }
  &::before {
    line-height: 1.5;
  }
  &::after {
    line-height: 1.5;
  }

  @media all and (min-width: 641px) {
    width: 90px;
    height: 90px;
    font-size: 15px;
    box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.15);
  }

  @media all and (max-width: 640px) {
    width: 49px;
    height: 49px;
    font-size: 8px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
  }
`

const Input = styled.input`
  border: 1px solid ${props => props.error ? '#dc0e0e' : (props.success ? Colors.green : '#d0d0d0')};
  background-color: white;
  box-sizing: border-box;
  color: ${props => props.error ? '#dc0e0e' : Colors.black};
  -webkit-appearance: none;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${props => props.error ? '#dc0e0e' : (props.success ? Colors.green : '#8d8d8d')};
  }

  @media all and (min-width: 641px) {
    width: 476px;
    height: 56px;
    font-size: 18px;
    padding-left: 20px;
  }

  @media all and (max-width: 640px) {
    width: 280px;
    height: 38px;
    font-size: 12px;
    padding-left: 10px;
  }
`

const Select = styled.select`
  border: 1px solid ${props => props.error ? '#dc0e0e' : (props.success ? Colors.green : '#d0d0d0')};
  background-color: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(${CaretDown}) no-repeat 97% 50%;
  color: ${props => props.error ? '#dc0e0e' : (props.success ? Colors.green : '#8d8d8d')};
  &:focus {
    outline: none;
  }

  @media all and (min-width: 641px) {
    width: 476px;
    height: 56px;
    font-size: 18px;
    padding-left: 20px;
  }

  @media all and (max-width: 640px) {
    width: 280px;
    height: 38px;
    font-size: 12px;
    padding-left: 10px;
  }
`

const RoundBtn = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  box-sizing: border-box;
  font-family: 'GmarketSans', sans-serif;

  @media all and (min-width: 641px) {
    width: 198px;
    height: 68px;
    border-radius: 34px;
    font-size: 20px;
    border: 2px solid white;
  }

  @media all and (max-width: 640px) {
    width: 125px;
    height: 40px;
    border-radius: 20px;
    font-size: 12px;
    border: 1px solid white;
  }
`

const Title = styled.p`
  margin: 0;
  color: ${Colors.black};
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'GmarketSans', sans-serif;

  @media all and (min-width: 641px) {
    font-size: 40px;
    &::before {
      content: '◆';
      font-size: 6px;
      padding-right: 27px;
    }
    &::after {
      content: '◆';
      font-size: 6px;
      padding-left: 27px;
    }
  }

  @media all and (max-width: 640px) {
    font-size: 24px;
    text-align: center;
    word-break: keep-all;
    line-height: 1.7;
  }
`

const Description = styled.div`
  font-family: 'NotoSans', sans-serif;
  text-align: center;
  color: ${Colors.black};
  word-break: keep-all;

  @media all and (min-width: 641px) {
    font-size: 18px;
  }

  @media all and (max-width: 640px) {
    font-size: 12px;
  }
`

const ContractModal = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  top: 0;
  background-color: rgba(0, 0, 0, .5);
  position: fixed;
  justify-content: flex-end;
  width: 100vw;
  height: 100%;

  &>div {
    background-color: ${Colors.white};
  }
  
  @media all and (min-width: 700px) {
    &>div {
      width: 700px;
    }
  }

  @media all and (max-width: 699px) {
    &>div {
      width: 100%;
    }
  }
`

const Styles = ({
  Contents, Container, GreenContainer, Paper, Button, ContactBtn, ContractModal, Input, Select, RoundBtn, Title, Description
})

export default Styles
