import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Colors from "../assets/theme/colors";
import Poster from "../assets/image/poster221227.jpg";
import { ownerWindow } from "@material-ui/core";

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const Label = styled.label`
  cursor: pointer;
  display: flex;
  align-items: flex-start;
`;

const Text = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  font-size: 14px;
  & > * {
    color: ${Colors.black};
    text-decoration: none;
  }
  & > div {
    cursor: pointer;
  }
`;

const Popup = (props) => {
  const { handleDownload } = props;

  const [isChecked, setChecked] = useState(false);

  useEffect(() => {
    if (isChecked) {
      let expires = new Date();
      expires = expires.setHours(expires.getHours() + 24);
      localStorage.setItem("hasVisitedBefore", expires);

      //임시방편 : 팝업창이 자식창이기 때문에, 부모창을 reload 시키는 방법으로
      window.opener.location.reload();
      window.close();
    }
  }, [isChecked]);

  return (
    <div>
      <Image src={Poster} />
      <Text>
        <div onClick={handleDownload}>입주자 모집 공고문 다운로드</div>
        <Label htmlFor="checkbox">
          <input
            id="checkbox"
            type="checkbox"
            onChange={() => setChecked(!isChecked)}
          />
          하루동안 이 창을 열지 않음
        </Label>
      </Text>
    </div>
  );
};

export default Popup;
