import React from "react";
import styled from "styled-components";
import Styles from "../assets/theme/styles";
import Colors from "../assets/theme/colors";
import GroundnoteIcon from "../components/GroundnoteIcon";
import InstagramIcon from "../components/InstagramIcon";
// import YoutubeIcon from '../components/YoutubeIcon'

const Contents = styled(Styles.Contents)`
  border-top: 1px solid #d8d8d8;
  color: ${Colors.grey};
  padding-top: 40px;
  padding-bottom: 40px;
`;

const Container = styled(Styles.Container)`
  max-width: 1920px;
  @media all and (min-width: 1280px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 160px;
  }
`;

const Title = styled.p`
  font-family: "GmarketSans", sans-serif;

  @media all and (min-width: 1280px) {
    display: none;
  }

  @media all and (max-width: 1279px) {
    margin: 0;
    font-size: 15px;
    font-weight: bold;
    padding-bottom: 30px;
  }
`;

const Text = styled.p`
  font-family: "GmarketSans", sans-serif;
  font-weight: 500;
  margin: 0;

  @media all and (min-width: 1280px) {
    font-size: 14px;
  }

  @media all and (max-width: 1279px) {
    font-size: 12px;
    padding-bottom: 35px;
    max-width: 205px;
    line-height: 1.6;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  @media all and (min-width: 1280px) {
    height: 32px;
    img {
      width: 76px;
      height: 29px;
      margin-right: 30px;
    }
    // &>div:last-child {
    //   margin-left: 34px;
    // }
  }

  @media all and (max-width: 1279px) {
    height: 25px;
    img {
      width: 63px;
      height: 25px;
      margin-right: 25px;
    }
    div {
      svg {
        width: 24px;
        height: 24px;
      }
    }
    // &>div:last-child {
    //   margin-left: 29px;
    // }
  }
`;

const Footer = () => {
  return (
    <Contents>
      <Container>
        <Title>대전청년하우스</Title>
        <Text>Copyright © 2020 대전청년하우스 All Rights Reserved.</Text>
        <SocialIcons>
          <GroundnoteIcon />
          <div>
            <InstagramIcon color={Colors.grey} />
          </div>
          {/* <div>
            <YoutubeIcon color={Colors.grey} />
          </div> */}
        </SocialIcons>
      </Container>
    </Contents>
  );
};

export default Footer;
