import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import Styles from "../assets/theme/styles";
import Colors from "../assets/theme/colors";
import animateScrollTo from "animated-scroll-to";
import MenuIcon from "../components/MenuIcon";
import CloseIcon from "../assets/image/svg/close.svg";
import GroundnoteIcon from "../components/GroundnoteIcon";
import InstagramIcon from "../components/InstagramIcon";
import ScrollToTop from "./ScrollToTop";
// import YoutubeIcon from '../components/YoutubeIcon'

const Contents = styled(Styles.Contents)`
  z-index: 800;
  position: ${(props) => (props.position ? props.position : "relative")};
  box-sizing: border-box;

  @media all and (min-width: 1280px) {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: ${(props) =>
      props.color !== undefined ? props.color : "rgba(0,0,0,0)"};
  }

  @media all and (max-width: 1279px) {
    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 10px;
    ${(props) =>
      !props.toggle &&
      css`
        position: fixed;
        background-color: ${Colors.green};
        padding-bottom: 30px;
        height: 100vh !important;
      `}
  }

  @media all and (min-width: 641px) {
    min-width: ${(props) => !props.toggle && "auto"};
    border-bottom: 1px solid
      ${(props) =>
        props.toggle && props.border !== undefined
          ? props.border
          : "rgba(0,0,0,0)"};
    background-image: url(${(props) =>
      props.toggle && props.bg !== undefined ? props.bg.image : ""});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: ${(props) =>
      props.toggle && props.bg !== undefined ? props.bg.height + "px" : "auto"};
  }

  @media all and (max-width: 640px) {
    background-image: url(${(props) =>
      props.toggle && props.mobileBg !== undefined
        ? props.mobileBg.image
        : ""});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    height: ${(props) =>
      props.toggle && props.mobileBg !== undefined
        ? props.mobileBg.height + "px"
        : "auto"};
  }
`;

const Container = styled(Styles.Container)`
  max-width: 1920px;
  @media all and (min-width: 1280px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media all and (max-width: 1279px) {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
  }
`;

const Navbar = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  @media all and (min-width: 1280px) {
    display: flex;
    flex-direction: row;
    padding-right: 161px;
    li:not(:nth-child(5)) {
      margin-right: 50px;
    }
    .mobileli {
      display: none;
    }
    span {
      color: ${(props) =>
        props.color !== undefined ? props.color : Colors.black};
      hr {
        background-color: ${(props) =>
          props.color !== undefined ? props.color : Colors.black};
      }
    }
  }

  @media all and (max-width: 1279px) {
    display: ${(props) => (props.toggle ? "none" : "flex")};
    width: 100%;
    flex-direction: column;
    margin-bottom: 10vh;
  }
`;

const Navli = styled.li`
  a {
    text-decoration: none;
  }
  .selected {
    span {
      position: relative;
    }
    hr {
      display: block;
    }
  }

  @media all and (min-width: 1280px) {
    .selected {
      span {
        font-weight: bold;
      }
    }
  }

  @media all and (max-width: 1279px) {
    a {
      display: block;
      padding: 12px 20px;
    }
  }
`;

const Logo = styled.div`
  a {
    font-family: "GmarketSans", sans-serif;
    font-weight: bold;
    text-decoration: none;
  }

  img,
  svg {
    cursor: pointer;
  }

  @media all and (min-width: 1280px) {
    padding-left: 160px;
    a {
      font-size: 30px;
      color: ${Colors.green};
    }
    div {
      display: none;
    }
  }

  @media all and (max-width: 1279px) {
    img {
      color: ${Colors.green};
    }
    width: ${(props) => (props.toggle ? "100%" : "100vw")};
    display: flex;
    justify-content: ${(props) =>
      props.toggle ? "space-between" : "flex-end"};
    align-items: center;
    a {
      display: ${(props) => !props.toggle && "none"};
      font-size: 15px;
      color: ${Colors.green};
    }
    div {
      display: contents;
    }
  }
`;

const SocialIcons = styled.div`
  @media all and (min-width: 1280px) {
    display: none;
  }

  @media all and (max-width: 1279px) {
    display: ${(props) => (props.toggle ? "none" : "flex")};
    margin-left: 20px;
    div {
      svg {
        width: 24px;
        height: 24px;
      }
    }
    div:first-child {
      margin-right: 25px;
    }
    div:nth-child(2) {
      margin-right: 27px;
    }
    img {
      width: 63px;
      height: 24px;
    }
  }
`;

const Text = styled.span`
  font-family: "GmarketSans", sans-serif;
  hr {
    display: none;
  }

  @media all and (min-width: 1280px) {
    font-size: 18px;
  }

  @media all and (max-width: 1279px) {
    font-size: 20px;
    font-weight: bold;
    color: white;
  }
`;

const UnderLine = styled.hr`
  border: 0;
  margin-bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;

  @media all and (min-width: 1280px) {
    bottom: -6px;
  }

  @media all and (max-width: 1279px) {
    bottom: -3px;
    background-color: white;
  }
`;

const Header = (props) => {
  let width = document.body.offsetWidth;
  const [toggle, setToggle] = useState(true);

  const onToggle = (text) => {
    width = document.body.offsetWidth;
    if (width <= 1279) {
      setToggle(!toggle);
    } else setToggle(true);

    setTimeout(() => {
      if (text === "faq") {
        animateScrollTo(document.querySelector("#faq"));
      } else window.scrollTo(0, 0);
    }, 100);
  };

  return (
    <Contents
      toggle={toggle}
      position={props.position}
      color={props.bgcolor}
      border={props.border}
      bg={props.bg}
      mobileBg={props.mobileBg}
    >
      <Container>
        <Logo toggle={toggle}>
          <NavLink exact to="/">
            대전청년하우스
          </NavLink>
          <div onClick={onToggle}>
            {toggle ? (
              <MenuIcon color={props.mobileColor} />
            ) : (
              <img src={CloseIcon} alt="close" />
            )}
          </div>
        </Logo>
        <Navbar toggle={toggle} color={props.color}>
          <Navli onClick={onToggle}>
            <NavLink activeClassName="selected" exact to="/">
              <Text>
                Home
                <UnderLine />
              </Text>
            </NavLink>
          </Navli>
          <Navli onClick={onToggle}>
            <NavLink activeClassName="selected" to="/about">
              <Text>
                About
                <UnderLine />
              </Text>
            </NavLink>
          </Navli>
          <Navli onClick={onToggle}>
            <NavLink activeClassName="selected" to="/house">
              <Text>
                House
                <UnderLine />
              </Text>
            </NavLink>
          </Navli>
          <Navli onClick={onToggle}>
            <NavLink activeClassName="selected" to="/community">
              <Text>
                Community
                <UnderLine />
              </Text>
            </NavLink>
          </Navli>
          <Navli onClick={() => onToggle("faq")}>
            <NavLink activeClassName="selected" exact to="/faq">
              <Text>
                FAQ
                <UnderLine />
              </Text>
            </NavLink>
          </Navli>
          <Navli onClick={onToggle} className="mobileli">
            <NavLink
              exact
              to="#none"
              onClick={() => {
                if (props.contract) props.contract(true);
              }}
            >
              <Text>Booking</Text>
            </NavLink>
          </Navli>
        </Navbar>
        <SocialIcons toggle={toggle}>
          <div>
            <InstagramIcon color="white" />
          </div>
          {/* <div>
            <YoutubeIcon color="white" />
          </div> */}
          <GroundnoteIcon white />
        </SocialIcons>
      </Container>
    </Contents>
  );
};

export default Header;
