import React from 'react'

const AboutIcons = props => {
  if (props.id === 'social') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "54"} height="36" viewBox="0 0 54 36">
        <defs>
          <filter id="lv3jure7va">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"/>
          </filter>
        </defs>
        <g fill="none" fill-rule="evenodd">
          <g filter="url(#lv3jure7va)" transform="translate(-332 -2167)">
            <g fill="#000">
              <path d="M4.457 9.999h45.086c.895 0 1.68.594 1.926 1.458l2.486 8.784.045.313c0 .47-.286.904-.742 1.08l-.003.002c-.54.207-1.15-.011-1.428-.52l-2.575-4.66-.738 17.926c-.038.907-.78 1.618-1.685 1.618h-.002c-.884 0-1.611-.688-1.678-1.57-.154-1.947-.56-6.6-.684-8.442-.05-.765-.61-1.215-1.218-1.215-.544 0-1.156.45-1.208 1.215-.124 1.842-.531 6.495-.684 8.441-.067.883-.792 1.571-1.678 1.571h-.003c-.904 0-1.647-.711-1.685-1.618-.194-4.68-.979-19.656-.979-19.656h-3.755s-.785 14.976-.979 19.656c-.038.905-.78 1.618-1.685 1.618h-.002c-.887 0-1.611-.688-1.679-1.57-.153-1.947-.56-6.6-.684-8.442-.052-.765-.663-1.215-1.208-1.215h-.009c-.544 0-1.159.45-1.208 1.215-.124 1.842-.531 6.495-.684 8.441-.068.883-.794 1.571-1.679 1.571h-.002c-.904 0-1.647-.711-1.685-1.618-.194-4.68-.979-19.656-.979-19.656H16.99s-.785 14.976-.979 19.656c-.038.907-.78 1.618-1.685 1.618h-.003c-.886 0-1.61-.688-1.678-1.57-.153-1.947-.56-6.6-.684-8.442-.052-.765-.664-1.215-1.208-1.215-.608 0-1.168.45-1.217 1.215-.124 1.842-.531 6.495-.684 8.441C8.784 35.313 8.056 36 7.172 36h-.002c-.905 0-1.647-.711-1.686-1.618l-.738-17.925-2.573 4.66c-.28.508-.89.726-1.43.519l-.001-.002c-.457-.176-.743-.61-.743-1.08l.045-.313 2.486-8.784c.245-.864 1.03-1.458 1.926-1.458zM11.25 0c2.484 0 4.5 2.016 4.5 4.5S13.734 9 11.25 9s-4.5-2.016-4.5-4.5S8.766 0 11.25 0zm31.5 0c-2.484 0-4.5 2.016-4.5 4.5S40.266 9 42.75 9s4.5-2.016 4.5-4.5S45.234 0 42.75 0zM27.014 0c2.483 0 4.5 2.016 4.5 4.5S29.497 9 27.014 9c-2.485 0-4.5-2.016-4.5-4.5s2.015-4.5 4.5-4.5z" transform="translate(332 2167)"/>
            </g>
          </g>
        </g>
      </svg>
    )
  } else if (props.id === 'coin') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "34"} height="40" viewBox="0 0 34 40">
        <defs>
          <filter id="oejwtfigla">
              <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"/>
          </filter>
        </defs>
        <g fill="none" fill-rule="evenodd">
          <g filter="url(#oejwtfigla)" transform="translate(-1242 -2560)">
            <g fill="#000" fill-rule="nonzero">
              <path d="M16.818 15c8.99 0 16.818-3.358 16.818-7.5 0-4.142-7.829-7.5-16.818-7.5C7.831 0 0 3.358 0 7.5 0 11.642 7.83 15 16.818 15zm.315-6.698c-1.006-.3-4.084-.554-4.084-2.25 0-.947 1.124-1.79 3.224-1.979v-.74h1.08v.704c.787.018 1.663.103 2.639.3l-.394 1.141c-.741-.168-1.569-.331-2.381-.331l-.244.003c-1.618.067-1.75.957-.627 1.332 1.836.561 4.258.975 4.258 2.465 0 1.19-1.458 1.828-3.253 1.986v.734h-1.08v-.694c-1.108-.01-2.275-.188-3.237-.506l.496-1.144c.821.209 1.854.422 2.784.422.244 0 .477-.015.701-.047 1.24-.176 1.487-1.003.118-1.396zM16.818 35c6.362 0 12.964-1.615 16.818-4.672V32.5c0 4.142-7.829 7.5-16.818 7.5C7.831 40 0 36.642 0 32.5v-2.173C3.855 33.385 10.456 35 16.818 35zm0-16.667c6.362 0 12.964-1.615 16.818-4.671v2.171c0 4.142-7.829 7.5-16.818 7.5C7.831 23.333 0 19.975 0 15.833v-2.171c3.855 3.056 10.456 4.671 16.818 4.671zm0 8.334c6.362 0 12.964-1.615 16.818-4.672v2.172c0 4.141-7.829 7.5-16.818 7.5C7.831 31.667 0 28.308 0 24.167v-2.174c3.855 3.059 10.456 4.674 16.818 4.674z" transform="translate(1242 2560)"/>
            </g>
          </g>
        </g>
      </svg>
    )
  } else if (props.id === 'service') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "40"} height="48" viewBox="0 0 40 48">
        <defs>
          <filter id="vag9lci9pa">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"/>
          </filter>
        </defs>
        <g fill="none" fill-rule="evenodd">
          <g filter="url(#vag9lci9pa)" transform="translate(-787 -2155)">
            <g fill="#000">
              <path d="M10.005 32.96l3.053-1.448c3.585-1.622 5.704-.689 8.702.465 3.433 1.323 4.732 1.768 6.157 2.33 2.557 1.013 1.376 4.359-1.185 3.68-1.557-.412-5.627-1.592-6.763-1.864-1.28-.308-1.86 1.205-.646 1.638 2.213.787 5.328 1.582 6.99 2.016 1.664.437 2.592-.29 3.765-1.223 1.812-1.442 5.938-5.825 5.938-5.825 1.685-1.6 3.984-.27 3.984 1.442 0 .46-.166.949-.552 1.416-4.658 5.592-6.123 7.291-9.14 10.463C29.06 47.36 27.622 48 25.898 48c-.885 0-1.845-.168-2.888-.5-3.651-1.164-8.347-2.63-13.005-3.204V32.96zM8.004 46.05H0V30.033h8.004V46.05zM38.017 23.03l-13.006 6.943V15.53l13.006-6.697V23.03zm-15.007-7.55v14.447L10.09 23.031V8.749l12.921 6.732zM16.191 4.29l13.058 6.803-5.194 2.675-12.908-6.725 5.044-2.753zm20.772 2.833l-5.535 2.849-13.122-6.838L24.053 0l12.91 7.124z" transform="translate(787 2155)"/>
            </g>
          </g>
        </g>
      </svg>
    )
  } else if (props.id === 'phone') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "27"} height="47" viewBox="0 0 27 47">
        <defs>
          <filter id="77lnoudzaa">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"/>
          </filter>
        </defs>
        <g fill="none" fill-rule="evenodd">
          <g filter="url(#77lnoudzaa)" transform="translate(-332 -2553)">
            <g fill="#000" fill-rule="nonzero">
              <path d="M27 3.917C27 1.755 25.272 0 23.143 0H3.857C1.728 0 0 1.755 0 3.917v39.166C0 45.245 1.728 47 3.857 47h19.286C25.272 47 27 45.245 27 43.083V3.917zm-16.393 0h5.786c.532 0 .964.438.964.979 0 .54-.432.979-.964.979h-5.786c-.532 0-.964-.439-.964-.98 0-.54.432-.978.964-.978zM13.5 43.083c-1.066 0-1.929-.877-1.929-1.958s.863-1.958 1.929-1.958c1.065 0 1.927.877 1.927 1.958s-.862 1.958-1.927 1.958zm9.643-5.875H3.857V9.745h19.286v27.463z" transform="translate(332 2553)"/>
            </g>
          </g>
        </g>
      </svg>
    )
  } else if (props.id === 'joy') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "47"} height="48" viewBox="0 0 47 48">
        <defs>
          <filter id="atjiwgt0ra">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"/>
          </filter>
        </defs>
        <g fill="none" fill-rule="evenodd">
          <g filter="url(#atjiwgt0ra)" transform="translate(-1242 -2155)">
            <g fill="#000">
              <path d="M4.896 38.49l1.598 3.012 3.298.62-2.311 2.482L7.92 48l-3.025-1.478L1.87 48l.44-3.396L0 42.122l3.298-.62 1.598-3.012zm37.782-7.702l-1.796-.652-3.81 10.942c-2.82-1.712-6.896-.526-7.795 2.056-.756 2.17.54 3.906 2.372 4.572 2.173.79 5.109.07 6.233-3.148l2.88-8.27c2.782 3.532 3.684 3.958 3.167 6.61 1.34-1.244 1.963-2.196 2.358-3.328 1.205-3.458-4.578-6-3.609-8.782zM27.775 6.102L11.854 17.088l5.58 14.532c-3.504.646-5.839 4.486-4.775 7.254.914 2.382 3.202 2.86 5.181 2.066 2.352-.94 4.27-3.674 2.942-7.142L16.119 21.65l12.042-7.984 3.691 9.612c-3.497.646-5.836 4.476-4.776 7.236.916 2.39 3.255 2.854 5.225 2.064 2.334-.934 4.23-3.656 2.904-7.124l-7.43-19.352zm-22.88 16.01l-4.895 5 4.896 5 4.896-5-4.896-5zM40.824 6l2.016 3.8 4.161.784-2.916 3.132L44.64 18l-3.817-1.864L37.005 18l.556-4.284-2.916-3.132 4.161-.784L40.823 6zM16.945 1.238L15.255 0l-6.43 9.15C6.75 5.906 2.744 5.048.681 7.986-.594 9.8.013 12.36 1.734 13.622c2.048 1.502 5.221 1.82 7.117-.87l8.093-11.514z" transform="translate(1242 2155)"/>
            </g>
          </g>
        </g>
      </svg>
    )
  } else if (props.id === 'house') {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : "48"} height="44" viewBox="0 0 48 44">
        <defs>
          <filter id="iz714nxl8a">
            <feColorMatrix in="SourceGraphic" values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"/>
          </filter>
        </defs>
        <g fill="none" fill-rule="evenodd">
          <g filter="url(#iz714nxl8a)" transform="translate(-787 -2556)">
            <g fill="#000">
              <path d="M18 38h-6v6H4V26.468l11.396-9.842 3.422 2.768L32 8l12 10.472V44H34v-8h-6v8H18v-6zm-4-10h-4v4h4v-4zm6 0h-4v4h4v-4zm10-2h-4v4h4v-4zm6 0h-4v4h4v-4zM18.716 11.494L32 0l16 14-2.658 2.99L32 5.352 18.752 16.828 15.396 14 2.658 24.99 0 22 15.396 8.648l3.32 2.846zM30 20h-4v4h4v-4zm6 0h-4v4h4v-4z" transform="translate(787 2556)"/>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default AboutIcons
