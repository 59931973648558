import React, { useState } from 'react'
import Contents from './components/Contents'
import Footer from './components/Footer'
import Contract from './components/Contract'

function App() {
  const [openContract, setOpenContract] = useState(false)
  const pathname = window.location.pathname

  return (
    <div>
      <Contents openContract={openContract} onOpenContract={setOpenContract} />
      {pathname !== '/popup' && <Footer />}
      <Contract open={openContract} onClose={setOpenContract} />
    </div>
  )
}

export default App
