import React from 'react'
import styled, { css } from 'styled-components'
import Colors from '../assets/theme/colors'

const Button = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  background-color: ${props => props.active ? Colors.green : 'white'};
  font-weight: 500;
  color: ${props => props.active ? 'white' : '#aaaaaa'};
  border: ${props => props.active ? '0' : '1px solid #d1d1d1'};
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'GmarketSans', sans-serif;

  @media all and (min-width: 641px) {
    width: 237px;
    height: 56px;
    font-size: 16px;
    ${props =>
      props.active &&
      css`
        position: absolute;
        top: -5px;
        left: -5px;
      `
    }
  }

  @media all and (max-width: 640px) {
    width: 157px;
    height: 32px;
    font-size: 10px;
  }
`

const Outline = styled.div`
  @media all and (min-width: 641px) {
    ${props =>
      props.active &&
      css`
        position: relative;
        box-sizing: border-box;
        border: 0.5px solid ${Colors.green};
        background-color: white;
        width: 237px;
        height: 56px;
        margin-left: 4px;
        margin-top: 4px;
      `
    }
  }
`

const TypeBtn = (props) => {
  const { active, children, ...rest } = props
  return (
    <Outline active={active}>
      <Button active={active} {...rest}>{children}</Button>
    </Outline>
  )
}

export default TypeBtn
