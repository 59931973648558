import React, { useState } from "react";
import styled from "styled-components";
import Colors from "../assets/theme/colors";
import Collapse from "@material-ui/core/Collapse";
import DownIcon from "../assets/image/svg/down.svg";

const Info = styled.div`
  & > * {
    font-family: "GmarketSans", sans-serif;
  }
  &:first-child {
    border-top: 1px solid #404040;
  }
  border-bottom: 1px solid #c9c9c9;

  @media all and (min-width: 641px) {
  }

  @media all and (max-width: 640px) {
  }
`;

const InfoTitle = styled.div`
  cursor: pointer;
  color: ${(props) => (props.toggle ? Colors.green : Colors.black)};
  display: flex;
  justify-content: space-between;
  align-items: center;

  img.up {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  @media all and (min-width: 641px) {
    padding: 35px;
    font-size: 18px;
  }

  @media all and (max-width: 640px) {
    padding: 20px;
    font-size: 15px;
  }
`;

const InfoContent = styled.div`
  border-top: 1px solid #c9c9c9;

  @media all and (min-width: 641px) {
    padding: 70px 35px;
  }

  @media all and (max-width: 640px) {
    padding: 35px 20px;
  }
`;

const InfoList = styled.ul`
  margin: 0 0 0 20px;
  padding: 0;
  list-style: none;
  li:first-child {
    padding-top: 0;
  }
  li:last-child {
    padding-bottom: 0;
  }

  @media all and (min-width: 641px) {
  }

  @media all and (max-width: 640px) {
  }
`;

const InfoDescription = styled.li`
  color: ${Colors.black};
  font-weight: 500;

  @media all and (min-width: 641px) {
    padding: 15px 0;
    font-size: 14px;
  }

  @media all and (max-width: 640px) {
    padding: 5px 0;
    font-size: 10px;
  }
`;
const Picture = styled.div`
  width: 100%;
  div {
    display: flex;
    justify-content: center;
  }
  img {
    padding: 5px;
    pointer-events: none;
  }

  @media all and (max-width: 640px) {
    div {
      flex-wrap: wrap;
    }

    // div {
    //   width: 400px;
    //   display: inline-block;
    //   justify-content: center;

    // }
    // img {
    //   margin: 0 auto;
    // }
  }
`;

const Faq = ({ info }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <Info>
      <InfoTitle toggle={toggle} onClick={() => setToggle(!toggle)}>
        {info.title}
        <img
          src={DownIcon}
          alt={toggle ? "Up" : "Down"}
          className={toggle ? "up" : "down"}
        />
      </InfoTitle>
      <Collapse in={toggle}>
        <InfoContent>
          <InfoList>
            <InfoDescription>
              <Picture>
                <div>
                  <img src={info.webimage} />
                  <img src={info.webimage2} />
                </div>
              </Picture>
            </InfoDescription>
          </InfoList>
        </InfoContent>
      </Collapse>
    </Info>
  );
};

export default Faq;
